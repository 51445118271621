import React, { useEffect, useState } from "react";
import {
  useSetState,
  toastifyError,
  createExcelFile,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import "./center.screen.scss";
import "react-responsive-modal/styles.css";
import DeletePopup from "components/delete_popup/delete_popup.component";
import Table from "components/table/table.component";
import Button from "common_components/ui/button/button.ui";
import Search from "common_components/ui/search/search.ui";
import Assets from "imports/assets.import";
import CustomModal from "common_components/ui/modal/modal.component";
import { useNavigate } from "react-router-dom";
import { setCenter } from "utils/redux.utils";
import _ from "lodash";
import DateRangePicker from "common_components/ui/date_range_picker/date_range.component";
import Filter from "common_components/ui/filter/filter.ui";
import SelectDropdown from "common_components/ui/select_dropdown/select_dropdown.component";
import Pagination from "components/pagination/pagination.component";
import pako from "pako";
import { Buffer } from "buffer";

export default function Center() {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  // localstorage

  //state
  const [state, setState] = useSetState({
    data: [],
    search: "",
    currentPage: 1,
    totalItems: 0,
    openModal: false,
    viewModal: false,
    deleteModal: false,
    loading: true,
    isOpenModel: false,
    toggleFilter: false,
    filterCenter: "",
    center: [],
  });

  const thead = [
    { head: "Name", key: "controlCenterName" },
    { head: "In charge", key: "centerInCharge.email", isNested: true },
    { head: "Phone", key: "phone" },
    { head: "City", key: "city" },
  ];

  // hooks
  useEffect(() => {
    GetManyData();
  }, [state.search, state.filterCenter]);

  const GetManyData = async () => {
    try {
      let res: any;
      const body: any = {
        page: state.currentPage,
        pageSize: 10,
      };
      if (state.search?.length > 0) {
        body.search = state.search;
      }
      if (state.filterCenter?.length > 0) {
        body.centerId = state.filterCenter;
      }
      res = await Models.center.getManyCenter(body);
      setState({
        totalItems: res?.count,
        data: res?.data,
      });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };
  const getAllCenter = async () => {
    let data: any = [];
    try {
      const res: any = await Models.center.getManyCenter({});
      for (let center of res.data) {
        data.push({
          label:
            center?.centerInCharge?.fullName && center?.centerId
              ? `${center.centerInCharge?.fullName} - ${center?.centerId}`
              : center.centerInCharge?.fullName || center?.centerId,
          value: center?.centerId,
        });
      }
      data = _.orderBy(data, ["label"]);
      data.unshift({
        label: "ALL CENTERS",
        value: 0,
      });
      setState({ centerData: _.uniqBy(data, "label") });
    } catch (err: any) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getAllCenter();
  }, []);
  const DeleteCenter = async () => {
    try {
      await Models.center.deleteCenter({
        ids: [state.id],
        target: "delete",
        checkCreatedBy: false,
        hardDelParam: false,
        reverterParam: false,
      });
      setState({ id: "", deleteModal: false });
      GetManyData();
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const handleView = (data) => {
    setCenter(data);
    navigate(`/center/view/${data?._id}`);
  };
  const handleDelete = (data) => {
    setState({ id: data._id, deleteModal: true });
  };
  const handlePageChange = (page: number) => {
    setState({ currentPage: page });
  };
  const handleExport = async () => {
    const res: any = await Models.center.exportCenter();
    const compressedString = res.data;
    const compressedBuffer = Buffer.from(compressedString, "base64");
    const base64String = pako.ungzip(compressedBuffer, { to: "base64" });
    createExcelFile(base64String);
  };
  return (
    <div className="org_screen">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="head h3">Control Center</div>
        </div>
        <div className="search_wrapper">
          <Search
            placeholder={"Search by Name, State, City, Pincode, Phone Number"}
            value={state.search}
            onChange={(search) => setState({ search })}
          />

          <div className="button_group">
            <Filter
              onClick={() => setState({ toggleFilter: !state.toggleFilter })}
            />
            <Button
              value="New Center"
              onClick={() => {
                navigate("/center/add");
              }}
              boxIcon="bx-plus"
            />
            <Button
              value="Export"
              onClick={() => {
                handleExport();
              }}
            />
          </div>
        </div>
      </div>
      <div className="all_filter_container">
        {state.toggleFilter && (
          <div className="all_filter_dropdown_wrapper">
            <div className="all_filter_dropdown_item">
              <SelectDropdown
                notfound={"Not found"}
                placeholder={"Center Incharge - centerId"}
                data={state.centerData}
                onChange={(filterCenter: any) =>
                  setState({
                    filterCenter: filterCenter.value,
                    currentPage: 1,
                    totalItems: 0,
                  })
                }
                value={state.filterCenter}
              />
            </div>
          </div>
        )}
      </div>
      <div className="drivertable">
        <Table
          data={state.data}
          totalDocs={state.totalDocs}
          loading={state.loading}
          theads={thead}
          link=""
          loadMore={() => {}}
          actions={
            role === "SA"
              ? [
                  {
                    icon: "view",
                    onClick: handleView,
                  },

                  {
                    icon: "delete",
                    onClick: handleDelete,
                  },
                ]
              : [
                  {
                    icon: "view",
                    onClick: handleView,
                  },
                ]
          }
        />
      </div>
      <div className="driver_pagination">
        <Pagination
          totalItems={state.totalItems}
          itemsPerPage={10}
          currentPage={state.currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <CustomModal
        center
        open={state.deleteModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deleteModal: false })}
      >
        <DeletePopup
          onPress={DeleteCenter}
          onCancel={() => setState({ deleteModal: false })}
        />
      </CustomModal>
    </div>
  );
}

import * as yup from "yup";
const phoneRegExp = /^[0-9]{10}$/;
export const aadharNumberRegex = /^\d{12}$/;
export const licenseNumberRegex = /^[A-Za-z0-9]{6,}$/;
export const panNumberRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
export const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+{}|:<>?~])\S{8,}$/;
const gstRegex = /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[1-9A-Z]{1})$/;
const pinRegex = /^[1-9][0-9]{5}$/;
export const emailRegExp = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
const rcRegex = /^[a-zA-Z0-9]*$/;
const incRegex = /^[A-Z]{3}[0-9]{9}$/;
export const test = yup.object({});

export const user = yup.object({
  fullName: yup.string().required("enter full name"),
  role: yup.mixed().optional(),
  gender: yup.string().optional(),
  dob: yup.mixed().optional(),
  bloodGroup: yup.string().optional(),
  mobile: yup
    .number()
    .integer("Please enter an valid mobile number")
    .typeError("Please enter an valid mobile number")
    .moreThan(999999999, "mobile must be 10 digits")
    .optional(),
  wapNumber: yup
    .number()
    .integer("Please enter an valid mobile number")
    .typeError("Please enter an valid mobile number")
    .moreThan(999999999, "mobile must be 10 digits")
    .optional(),
  secondaryMobileNo: yup
    .number()
    .integer("Please enter an valid mobile number")
    .typeError("Please enter an valid mobile number")
    .moreThan(999999999, "mobile must be 10 digits")
    .optional(),
  fatherName: yup.string().optional(),
  languages: yup.mixed().optional(),
  email: yup
    .string()
    .required("Please enter email")
    .matches(emailRegExp, "Please enter valid email"),
  aadhaarDocument: yup.string().optional(),
  panDocument: yup.string().optional(),
  userImage: yup.string().optional(),
  controlCenterId: yup.string().optional(),
});
export const driverBasic = yup.object({
  mobile: yup
    .string()
    .min(10)
    .max(10)
    .required("Please enter mobile phone")
    .matches(phoneRegExp, "Please enter valid mobile number"),
  wapNumber: yup
    .string()
    .min(10)
    .max(10)
    .required("Please enter mobile phone")
    .matches(phoneRegExp, "Please enter valid mobile number"),
  gender: yup.string().required("Please select gender"),
  bloodGroup: yup.string().required("Please select blood group"),
  fullName: yup
    .string()
    .required("Please enter name")
    .matches(/^[a-zA-Z\s-]+$/, "Please enter alphabet letters only"),
  state: yup.string().required("Please select a state"),
  city: yup.string().required("Please select a city"),
  dob: yup.string().required("Please choose your birthDate"),
  driverRegistrationType: yup.string().required("Please select a status"),
  fullAddress: yup.string().required("Please enter full address"),
  fatherName: yup
    .string()
    .required("Please enter father's name")
    .matches(/^[a-zA-Z\s-]+$/, "Please enter alphabet letters only"),
  pinCode: yup
    .string()
    .required("Please enter pincode")
    .matches(pinRegex, "Please enter valid pin"),
  face: yup.string().required("Please upload profile pic"),
});
export const organization = yup.object({
  fullName: yup
    .string()
    .required("Please enter name")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  category: yup.string().required("Please select a category"),
  type: yup.string().required("Please select a type"),
  email: yup
    .string()
    .required("Please enter email")
    .matches(emailRegExp, "Please enter valid email"),
  mobile: yup
    .string()
    .min(10)
    .max(10)
    .required("Please enter mobile phone")
    .matches(phoneRegExp, "Please enter valid mobile number"),
  state: yup.string().required("Please select a state"),
  buildingName: yup.string().required("Please enter building name"),
  landMark: yup.string().required("Please enter landmark"),
  fullAddress: yup.string().required("Please enter full address"),
  pinCode: yup
    .string()
    .required("Please enter pincode")
    .matches(pinRegex, "Please enter valid pin"),
  gstNo: yup.string().optional(),
  // .matches(gstRegex, "Please enter valid GST Number"),
  panNumber: yup
    .string()
    .matches(panNumberRegex, "Invalid PAN card number")
    .optional(),
  contractPeriod: yup.string().required("Please enter contract period"),
  contractStartDate: yup.date().required("Please enter contract start date"),
  contractEndDate: yup.date().required("Please enter contract end date"),
});
export const vehicle = yup.object({
  city: yup.string().required("Please select a city"),
  state: yup.string().required("Please select a state"),
  vhModel: yup.string().required("Please enter model"),
  vhNumber: yup.string().required("Please enter number"),
  vhCompany: yup.string().required("Please enter company"),
  ownerName: yup.string().required("Please enter owner name"),
  vhType: yup.string().required("Please select type"),
  isOwnVehicle: yup.string().required("Please select yes/no"),
  rcNumber: yup
    .string()
    .required("Please enter rc number")
    .matches(rcRegex, "Please enter valid rc number"),
  insuranceNumber: yup.string().required("Please enter insurance number"),
  // .matches(incRegex, "Please enter valid insurance number"),
  vhImage: yup.string().required("Please upload vehicle image"),
  rcImageF: yup.string().required("Please upload rc book front"),
  rcImageB: yup.string().required("Please upload rc book back"),
  insuranceImage: yup.string().required("Please upload insurance doc"),
});
export const center = yup.object({
  controlCenterName: yup.string().required("Please enter center name"),
  phone: yup
    .string()
    .min(10)
    .max(10)
    .required("Please enter mobile phone")
    .matches(phoneRegExp, "Please enter valid mobile number"),
  state: yup.string().required("Please select a state"),
  fullAddress: yup.string().required("Please enter full address"),
  pinCode: yup
    .string()
    .required("Please enter pincode")
    .matches(pinRegex, "Please enter valid pin"),
  centerInCharge: yup.string().required("Please select a center incharge"),
});
export const store = yup.object({
  storeName: yup.string().required("Please enter center name"),
  phone: yup
    .string()
    .min(10)
    .max(10)
    .required("Please enter mobile phone")
    .matches(phoneRegExp, "Please enter valid mobile number"),
  state: yup.string().required("Please select a state"),
  fullAddress: yup.mixed().required("Please enter full address"),
  type: yup.string().required("Please select type"),
  controlCenterId: yup.string().required("Please select control center"),
  storeInCharge: yup.object().shape({
    name: yup.string().required("Name is required"),
    number: yup
      .string()
      .matches(/^[0-9]{10}$/, "Invalid phone number")
      .required("Phone number is required"),
    emailId: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
  }),
});
// _NV_

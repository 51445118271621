import React, { FC, useEffect, useRef } from 'react'


export type GoogleAutocompletePlacesValueType = {
  lng: number,
  lat: number,
  formatted_address?: string,
}
export interface GoogleAutocompletePlacesProps {
  value: GoogleAutocompletePlacesValueType,
  onChange: (data: GoogleAutocompletePlacesValueType) => void,
  label?: string
}
const GoogleAutocompletePlaces: FC<GoogleAutocompletePlacesProps> = ({
  value,
  onChange,
  label,
  ...rest
}) => {
  const inputRef = useRef<any>();
  const autoCompleteRef = useRef<any>();
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      let lat = place?.geometry?.location?.lat();
      let lng = place?.geometry?.location?.lng();
      onChange({ lat, lng, formatted_address: place?.formatted_address });
    });
  }, [])
  return (
    <div className="input_container">
      <div className="label_container caption2">{label}</div>
      <div className="field_wrap">
        <input ref={inputRef} name="address" type="text" className="input menu" placeholder="Door no, Street name, Area name" {...rest} />
        {/* {
            location.formatted_address &&
            <div className="error menu">Please enter address</div>
          } */}
      </div>
    </div>
  )
}

export default GoogleAutocompletePlaces
import Assets from "imports/assets.import";
import "./sidebar.ui.scss";
import { toastifyError, useSetState } from "utils/functions.utils";
import Divider from "../divider/divider.ui";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLogout } from "store/actions/auth.actions";
import { NavLink } from "react-router-dom";
import sidebarRoutes from "./sidebar_routes";
import { useEffect } from "react";
import Colors from "imports/color.import";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = localStorage.getItem("email");
  const dispatch = useDispatch();
  const handleLogout = async () => {
    dispatch(setLogout());
  };

  const navOptions = sidebarRoutes();
  useEffect(() => {
    console.log("LOCATION", location);
  }, []);
  return (
    <div className="sidebar_container">
      <div style={{ textAlign: "center" }} className="app_logo_container">
        <img
          src={Assets.fullfily_white_icon}
          className="app_logo_image"
          alt="app_logo"
          style={{ objectFit: "contain" }}
        />
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill={Colors.primary}
          fillOpacity="1"
          d="M0,128L80,149.3C160,171,320,213,480,197.3C640,181,800,107,960,90.7C1120,75,1280,117,1360,138.7L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        ></path>
      </svg>
      {/* <Divider style={{margin : "10px 0"}} /> */}
      <div className="sidebar_item_scroll">
        {navOptions.map((nav, index) => {
          // console.log(location.pathname, nav.link, nav.link + "/", nav.link);
          const isActive =
            location.pathname === nav.link ||
            location.pathname.includes(nav.link + "/") ||
            location.pathname.includes(nav.link);
          // const isActive = location.pathname.includes(
          //   nav.link.split("/").filter(Boolean).reverse()[0]
          // );
          return (
            <div
              key={index}
              className={`sidebar_item_container ${isActive ? "active" : ""}`}
              onClick={() => {
                navigate(nav.link);
              }}
            >
              <div className={`sidebar_item_wrapper`}>
                <div className={`sidebar_item_container_icon`}>
                  {nav.icon ? (
                    <img
                      src={Assets[nav.icon]}
                      width={22}
                      height={22}
                      className="sidebar_icon"
                      alt="view"
                    />
                  ) : null}
                  {nav.boxIcon ? (
                    <i
                      style={{ fontSize: 25 }}
                      className={`bx ${nav.boxIcon}`}
                    ></i>
                  ) : null}
                </div>
                <div className={`sidebar_label_container`}>
                  <span className="sidebar_label">{nav.label}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Divider style={{ margin: "10px 0" }} />
      <div className={`sidebar_item_container`}>
        <div onClick={handleLogout} className="sidebar_item_wrapper">
          <div className="sidebar_icon_container">
            <img
              src={Assets.logout}
              width={25}
              height={25}
              className="sidebar_icon"
              alt="view"
            />
          </div>
          <div className="sidebar_label_container">
            <div className="sidebar_label">Logout</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

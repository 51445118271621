import React, { useEffect } from "react";
import {
  useSetState,
  toastifyError,
  createExcelFile,
  toastify,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import "../../store/store.screen.scss";
import "react-responsive-modal/styles.css";
import DeletePopup from "components/delete_popup/delete_popup.component";
import Table from "components/table/table.component";
import Button from "common_components/ui/button/button.ui";
import Search from "common_components/ui/search/search.ui";
import Assets from "imports/assets.import";
import CustomModal from "common_components/ui/modal/modal.component";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import Pagination from "components/pagination/pagination.component";
import pako from "pako";
import { Buffer } from "buffer";
import { useDispatch, useSelector } from "react-redux";
import InputField from "common_components/ui/field/field.ui";
import Colors from "imports/color.import";
import { setClientPOVStoreLogin } from "store/actions/client.pov.store.actions";

export default function ClientPovStore() {
  const navigate = useNavigate();
  //redux
  const user = useSelector((s: any) => s.auth.user);
  const clientId = user?.clientId;
  console.log(clientId);
  const dispatch = useDispatch();
  //state
  const [state, setState] = useSetState({
    data: [],
    search: "",
    openModal: false,
    viewModal: false,
    openOtpBox: false,
    loading: false,
    organization: user,
    currentPage: 1,
    totalItems: 0,
    otpCode: "",
    selectedStoreForLogin: {},
    sendOtpLoading: null,
    verifyOtpLoading: false,
  });

  const thead = [
    { head: "Name", key: "storeName" },
    { head: "Address", key: "fullAddress" },
    { head: "Type", key: "type" },
    { head: "Mobile", key: "phone" },
    { head: "Store incharge", key: "storeInCharge.name", isNested: true },
  ];
  // hooks
  useEffect(() => {
    GetManyData();
    // GetOrganization();
  }, [state.search]);

  //network req
  const GetManyData = async () => {
    try {
      const body: any = {
        client: clientId,
        page: state.currentPage,
        pageSize: 10,
      };
      if (state.search.length > 0) {
        body.storeName = state.search;
      }
      setState({ loading: false });
      const res: any = await Models.store.getManyStore(body);
      setState({ data: res?.data, loading: false, totalItems: res?.count });
    } catch (error) {
      console.log(error);
    }
  };

  const handleView = (data) => {
    console.log(data);
    navigate(`/client-pov/${clientId}/store/view/${data._id}`);
  };
  const handleSendOtp = async (data) => {
    try {
      setState({ sendOtpLoadingWithId: data._id });
      const res: any = await Models.store.loginWithOtp(data._id);
      if (process.env.REACT_APP_ENVIRONMENT == "development") {
        setState({
          id: data._id,
          selectedStoreForLogin: { ...data, testOtp: res?.data?.otp },
          openOtpBox: true,
        });
      } else {
        setState({
          id: data._id,
          selectedStoreForLogin: data,
          openOtpBox: true,
        });
      }
    } catch (err) {
      console.log("SET STORE OTP ERR : ", err);
    } finally {
      setState({ sendOtpLoadingWithId: null });
    }
  };
  const handleVerifyOtp = async (data) => {
    if (state.otpCode.length === 4) {
      try {
        setState({ verifyOtpLoading: true });
        const res: any = await Models.store.verifyStoreOTP({
          sid: state?.selectedStoreForLogin?._id,
          otp: state.otpCode,
        });
        toastify(res?.message);
        await dispatch(
          setClientPOVStoreLogin({
            token: res.store_access_token,
            storeId: state?.selectedStoreForLogin?._id,
          })
        );
        setState({ openOtpBox: false });
      } catch (err) {
        console.log("SET STORE OTP ERR : ", err);
      } finally {
        setState({ verifyOtpLoading: false });
      }
    }
  };
  const handlePageChange = (page: number) => {
    setState({ currentPage: page });
  };
  const handleExport = async () => {
    const res: any = await Models.store.exportStore();
    const compressedString = res.data;
    const compressedBuffer = Buffer.from(compressedString, "base64");
    const base64String = pako.ungzip(compressedBuffer, { to: "base64" });
    createExcelFile(base64String);
  };
  useEffect(() => {
    return () => {
      setState({ openOtpBox: false });
    };
  }, []);
  return (
    <div className="store_screen">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="head h3">Stores</div>
        </div>
        <div className="search_wrapper">
          <Search
            value={state.search}
            onChange={(search) => setState({ search })}
          />
          <div className="button_group">
            <Button
              value="+ Export"
              onClick={() => {
                handleExport();
              }}
            />
            {/* <Button
              value="Add Store"
              onClick={() => {
                navigate(`/client-pov/${clientId}/store/add`);
              }}
            /> */}
          </div>
        </div>
      </div>
      <div className="storetable">
        <Table
          data={state.data}
          loading={state.loading}
          theads={thead}
          link="store"
          actions={[
            {
              icon: "view",
              onClick: handleView,
            },
            {
              buttonComponent: (
                <Button
                  style={{ padding: "5px 20px" }}
                  value="Request OTP"
                  loading={
                    state?.selectedStoreForLogin?._id &&
                    state.sendOtpLoadingWithId ===
                      state?.selectedStoreForLogin?._id
                  }
                  loadingText="Sending Otp..."
                />
              ),
              onClick: handleSendOtp,
            },
          ]}
          imageKey="logo"
        />
      </div>
      <div className="driver_pagination">
        <Pagination
          totalItems={state.totalItems}
          itemsPerPage={10}
          currentPage={state.currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <CustomModal
        center
        open={state.openOtpBox}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ openOtpBox: false })}
      >
        <div className="popup_container">
          <div className="popup_wrapper">
            <br />
            <h4>OTP Generated to {state.selectedStoreForLogin?.phone}</h4>
            {process.env.REACT_APP_ENVIRONMENT == "development" ? (
              <p style={{ textAlign: "center", color: Colors.red }}>
                we are in development so test otp is{" "}
                {state.selectedStoreForLogin?.testOtp}
              </p>
            ) : null}
            <br />
            <div className="add_field_container">
              <div className="input_container">
                <div style={{ textAlign: "center" }}>
                  <input
                    name={"otp-input"}
                    className="input"
                    type="number"
                    value={state.otpCode}
                    style={{ textAlign: "center", width: 150 }}
                    onChange={(e) => {
                      if (e.target.value.length <= 4)
                        setState({ otpCode: e.target.value });
                    }}
                    placeholder="Enter OTP..."
                  />
                  <br />
                  <br />
                  <Button
                    onClick={handleVerifyOtp}
                    value="Verify Otp"
                    loading={state.verifyOtpLoading}
                    loadingText="Verifying your OTP please wait..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

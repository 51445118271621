import "./table.component.scss";
import Assets from "imports/assets.import";
import { getNestedObjectValue } from "utils/functions.utils";
import moment from "moment";
import Functions from "utils/functions.utils";

interface tableProps {
  data?: any;
  totalDocs?: number;
  loading?: boolean;
  theads: {
    head: string;
    key: string;
    width?: number;
    type?: string;
    isNested?: boolean;
  }[];
  link: string;
  actions?: {
    icon?: string;
    onClick?: any;
    text?: string;
    textBackground?: string;
    hideIcon?: boolean;
    buttonComponent?: React.ReactNode;
  }[];
  imageKey?: string;
  loadMore?: any;
  img?: boolean;
}
export default function Table(props: tableProps) {
  const { theads, data, actions, img, imageKey = "" } = props;

  return (
    <div className="table_container">
      <div className="table_wrapper">
        <div className="table_head">
          <div className="table_row">
            {theads?.map((head) => (
              <div
                style={{ width: head.width }}
                className="table_cell table_head_text"
              >
                {head.head}
              </div>
            ))}
            <div style={{ width: 200 }} className="table_cell table_head_text">
              Actions
            </div>
          </div>
        </div>
        <div className="table_body">
          {data?.map((item: any, index: number) => (
            <div className="table_row" key={index}>
              {theads?.map((head, index) =>
                index === 0 ? (
                  <div className="table_cell" key={index}>
                    <div className="table_cell_image_wrapper">
                      <div className="table_cell_image_container">
                        {img && (
                          <img
                            src={item[imageKey] || Assets["user"]}
                            className="table_cell_image"
                            alt="User"
                          />
                        )}
                      </div>
                      <div className="body1">
                        {head.isNested ? (
                          getNestedObjectValue(item, head.key) || "---"
                        ) : head.type === "statuscheck" ? (
                          item[head.key] === "active" ? (
                            <img src={Assets.active} alt="Active" />
                          ) : item[head.key] === "in_active" ? (
                            <img src={Assets.inactive} alt="Inactive" />
                          ) : (
                            <img src={Assets.break_icon} alt="Break" />
                          )
                        ) : head.type === "date" ? (
                          moment(item[head.key]).format("DD-MM-YYYY, h:mma")
                        ) : head.type === "allocation" ? (
                          <img src={Assets.active} alt="Active" />
                        ) : (
                          item[head.key] || "---"
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="table_cell body1" key={index}>
                    {head.isNested
                      ? (() => {
                          const nestedValue = getNestedObjectValue(
                            item,
                            head.key
                          );
                          if (nestedValue !== undefined) {
                            return nestedValue !== null
                              ? (() => {
                                  if (
                                    head.type === "date" ||
                                    head.type === "datetime"
                                  ) {
                                    return moment(nestedValue).format(
                                      "DD-MM-YYYY, h:mma"
                                    );
                                  } else if (
                                    head.type === "ignition" ||
                                    head.type === "allocation"
                                  ) {
                                    return (
                                      <span
                                        style={{
                                          color:
                                            head.type === "ignition"
                                              ? nestedValue
                                                ? "green"
                                                : "red"
                                              : nestedValue
                                              ? "green"
                                              : "red",
                                        }}
                                      >
                                        {head.type === "ignition"
                                          ? nestedValue
                                            ? "On duty"
                                            : "Off duty"
                                          : nestedValue
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    );
                                  } else if (head.type === "statuscheck") {
                                    return nestedValue ? (
                                      <img src={Assets.active} alt="Active" />
                                    ) : (
                                      <img
                                        src={Assets.inactive}
                                        alt="Inactive"
                                      />
                                    );
                                  } else if (head.type === "min") {
                                    return Functions.timeConvert(nestedValue);
                                  } else {
                                    return nestedValue;
                                  }
                                })()
                              : "---";
                          } else {
                            return "---";
                          }
                        })()
                      : item[head.key] !== undefined
                      ? (() => {
                          if (
                            head.type === "date" ||
                            head.type === "datetime"
                          ) {
                            return moment(item[head.key]).format(
                              "DD-MM-YYYY, h:mma"
                            );
                          } else if (
                            head.type === "ignition" ||
                            head.type === "allocation"
                          ) {
                            return (
                              <span
                                style={{
                                  color:
                                    head.type === "ignition"
                                      ? item[head.key]
                                        ? "green"
                                        : "red"
                                      : item[head.key]
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {head.type === "ignition"
                                  ? item[head.key]
                                    ? "On duty"
                                    : "Off duty"
                                  : item[head.key]
                                  ? "Yes"
                                  : "No"}
                              </span>
                            );
                          } else if (head.type === "statuscheck") {
                            return item[head.key] ? (
                              <img src={Assets.active} alt="Active" />
                            ) : (
                              <img src={Assets.inactive} alt="Inactive" />
                            );
                          } else if (head.type === "min") {
                            return Functions.timeConvert(item[head.key]);
                          } else {
                            return item[head.key];
                          }
                        })()
                      : "---"}
                  </div>
                )
              )}
              <div className="table_cell">
                <div className="action_wrapper">
                  {actions?.map(
                    (
                      {
                        icon,
                        onClick,
                        text,
                        textBackground,
                        hideIcon,
                        buttonComponent,
                      },
                      actionIndex
                    ) => (
                      <div
                        className="action_button_container"
                        key={actionIndex}
                      >
                        <div
                          className="action_btn_wrapper"
                          onClick={() => {
                            if (onClick) onClick(item);
                          }}
                        >
                          {!hideIcon && icon && (
                            <div className={`action_btn`}>
                              <img
                                src={Assets[icon]}
                                width={25}
                                height={25}
                                alt="view"
                              />
                            </div>
                          )}
                          {text && (
                            <div
                              style={{ backgroundColor: textBackground }}
                              className="action_btn_text"
                            >
                              {text}
                            </div>
                          )}
                          {buttonComponent && buttonComponent}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import instance from "utils/axios.utils";
import Functions from "utils/functions.utils";
import { setManyCheckin, setCheckin } from "utils/redux.utils";

const Checkin = {
  getManyCheckin: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "checkIns";
        const response = await instance().get(url, { params: query });
        setManyCheckin(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getChecin: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "checkIns";
        const response = await instance().get(url, { params: id });
        setCheckin(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  exportCheckin: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "checkIns?export=1";
        const response = await instance().get(url);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
};

export default Checkin;

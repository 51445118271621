import React, { useEffect } from "react";
import History from "components/history/history.component";
import Add from "components/add/add.component";
// import Assets from "imports/assets.import";
import Button from "common_components/ui/button/button.ui";
import "./add_organization.screen.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  removeEmptyValues,
  useSetState,
  toastifyError,
  jsonToFormData,
  simplifyJSON,
  createPayloadUpdate,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import { IAddValues } from "utils/interface.utils";
import {
  AGREEMENT_DOC_UPLOAD_URL,
  CANCELLED_CHEQUE_UPLOAD_URL,
  FACE_BUCKET_URL,
  GST_UPLOAD_URL,
  PAN_BUCKET_URL,
} from "utils/constant.utils";

export default function AddOrganization() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const pathArr = pathname.split("/");
  const isEditable = pathArr.includes("edit");

  const initialValue = {
    city: "",
    state: "",
    fullName: "",
    email: "",
    mobile: "",
    userImage: "",
    gstNo: "",
    type: "",
    gstDoc: "",
    canceledCheque: "",
    panNumber: "",
    category: "",
    agreementDoc: "",
    panDocument: "",
    contractStartDate: new Date(),
    contractEndDate: new Date(),
    buildingName: "",
    landMark: "",
    fullAddress: "",
    pinCode: "",
    contractPeriod: "",
  };

  const [state, setState] = useSetState({
    data: initialValue,
    loading: false,
    buttonDisabled: false,
  });

  const inputFields: IAddValues[] = [
    {
      label: "Client name",
      key: isEditable ? "userId.fullName" : "fullName",
      type: "string",
    },
    {
      label: "Category",
      key: "category",
      options: [
        { label: "Large", value: "LARGE" },
        // { label: "Non Large", value: "NON_LARGE" },
        { label: "SMALL", value: "SMALL" },
        { label: "MEDIUM", value: "MEDIUM" },
      ],
      type: "select",
    },
    {
      label: "Type",
      key: "type",
      options: [
        { label: "DYNAMIC", value: "DYNAMIC" },
        { label: "DEDICATED", value: "DEDICATED" },
      ],
      type: "select",
    },
    {
      label: "Email",
      key: isEditable ? "userId.email" : "email",
      type: "string",
      hide: isEditable ? true : false,
    },
    {
      label: "Mobile",
      key: isEditable ? "userId.mobile" : "mobile",
      type: "string",
    },
    {
      label: "State",
      key: isEditable ? "userId.address[0].state" : "state",
      type: "state",
    },
    {
      label: "City",
      key: isEditable ? "userId.address[0].city" : "city",
      type: "city",
    },
    {
      label: "Building Name",
      key: isEditable ? "userId.address[0].buildingName" : "buildingName",
      type: "string",
    },
    {
      label: "Landmark",
      key: isEditable ? "userId.address[0].landMark" : "landMark",
      type: "string",
    },
    {
      label: "Full Address",
      key: isEditable ? "userId.address[0].fullAddress" : "fullAddress",
      type: "string",
    },
    {
      label: "Pincode",
      key: isEditable ? "userId.address[0].pinCode" : "pinCode",
      type: "string",
    },
    { label: "GST number", key: "gstNo", type: "string", optional: true },
    {
      label: "Pan card",
      key: isEditable ? "userId.panNumber" : "panNumber",
      type: "string",
      optional: true,
    },
    {
      label: "Contract period",
      key: "contractPeriod",
      type: "string",
    },
    {
      label: "Contract period start",
      key: "contractStartDate",
      type: "date",
    },
    {
      label: "Contract period end",
      key: "contractEndDate",
      type: "date",
    },
    {
      label: "Client Logo",
      key: isEditable ? "userId.userImage" : "userImage",
      type: "file",
      file_bucket_url: FACE_BUCKET_URL,
      secondaryKey: "public",
      optional: true,
    },
    {
      label: "GST document (Document must be above 1 MB size) ",
      key: "gstDoc",
      type: "file",
      file_bucket_url: GST_UPLOAD_URL,
      secondaryKey: "private",
      optional: true,
    },
    {
      label: "Canceled check (Document must be above 1 MB in size)",
      key: "canceledCheque",
      type: "file",
      file_bucket_url: CANCELLED_CHEQUE_UPLOAD_URL,
      secondaryKey: "private",
      optional: true,
    },

    {
      label: "Agreement Document (Document must be above 1 MB in size)",
      key: "agreementDoc",
      type: "file",
      file_bucket_url: AGREEMENT_DOC_UPLOAD_URL,
      secondaryKey: "private",
      optional: true,
    },
    {
      label: "Pan Document (Document must be above 1 MB in size)",
      key: isEditable ? "userId.panDocument" : "panDocument",
      type: "file",
      file_bucket_url: PAN_BUCKET_URL,
      secondaryKey: "private",
      optional: true,
    },
  ];

  useEffect(() => {
    if (isEditable) {
      GetOrganization();
    }
  }, []);
  const GetOrganization = async () => {
    try {
      const response: any = await Models.organization.getOrganization({
        id: params.id,
      });
      setState({ data: response.data[0] });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const handleEdit = async (values) => {
    console.log(values);
    const data: any = createPayloadUpdate(simplifyJSON(values), [
      "0",
      "apiKey",
      "bankDataUpdated",
      "basicDataUpdated",
      "cId",
      "clientId",
      "createdAt",
      "documentDataUpdated",
      "driverApproverStatus",
      "driverRegistrationType",
      "emergencyDataUpdated",
      "forgetTokenVerificationStatus",
      "isApiKeyInForce",
      "isApprovedByAdmin",
      "isAadhaarVerified",
      "isArchived",
      "isDefault",
      "isDefaultPasswordUpdated",
      "isDeleted",
      "isDriverApprovedByA",
      "isPanVerified",
      "registrationMethod",
      "updatedAt",
      "__v",
      "_id",
    ]);
    // console.log(data);
    data._id = state.data.userId._id;
    const formData = jsonToFormData(data);
    setState({ buttonDisabled: true });
    try {
      let res = await Models.organization.editOrganization(formData);
      navigate("/client");
    } catch (err) {
      setState({ buttonDisabled: false });
      console.log(err);
      toastifyError(err);
    }
  };

  const handleCreate = async (values) => {
    // setState({ buttonDisabled: true });
    try {
      values.cbp = true;
      const data: any = removeEmptyValues(values);
      const payload = jsonToFormData(values);

      await Models.organization.createOrganization(payload);
      navigate("/client");
    } catch (err) {
      // setState({ buttonDisabled: false });
      console.log(err);
      toastifyError(err);
    }
  };

  return (
    <div className="add_organization_container">
      <div className="add_organization_wrapper">
        <History
          name={state?.data?.userId?.fullName}
          path={isEditable ? "Client/edit" : "Client/add"}
        />
        <div className="add_organization_body_container">
          <Add
            title={`${isEditable ? "Edit" : "Add"} Organization`}
            actions={[
              { link: "/", icon: "view" },
              { link: "/", icon: "store" },
            ]}
            data={state.data}
            values={inputFields}
            initialValues={initialValue}
            validationSchema={isEditable ? "editOrganization" : "organization"}
            onSubmit={isEditable ? handleEdit : handleCreate}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
      </div>
    </div>
  );

  function AddButton(props: any) {
    return (
      <div className="view_button_container">
        <div className="view_button_wrapper">
          {isEditable ? (
            <div className="view_button">
              <Button
                width="250px"
                buttonDisabled={props.buttonDisabled}
                value="Update"
              />
            </div>
          ) : (
            <div className="view_button">
              <Button
                width="250px"
                buttonDisabled={props.buttonDisabled}
                value="Save"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

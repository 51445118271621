import { useEffect } from "react";
import History from "components/history/history.component";
import Add from "components/add/add.component";
import Button from "common_components/ui/button/button.ui";
import "./add_driver.screen.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  removeEmptyValues,
  useSetState,
  toastifyError,
  jsonToFormData,
  toastify,
  createPayloadUpdate,
  simplifyJSON,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import { IAddValues } from "utils/interface.utils";
import CustomModal from "common_components/ui/modal/modal.component";
import TextareaComponent from "common_components/ui/text_area/textarea.component";
import encrypt from "utils/encrypt";
import AddVerification from "components/add/addVerification";
import {
  AADHAAR_BUCKET_URL,
  DL_BUCKET_URL,
  FACE_BUCKET_URL,
  PAN_BUCKET_URL,
  PASSBOOK_BUCKET_URL,
} from "utils/constant.utils";

export default function AddDriver() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const pathArr = pathname.split("/");
  const isEditable = pathArr.includes("edit");

  const initialValue = {
    mobile: "",
    wapNumber: "",
    secondaryMobileNo: "",
    gender: "",
    bloodGroup: "",
    fullName: "",
    state: "",
    city: "",
    birthdate: "",
    driverRegistrationType: "",
    fullAddress: "",
    fatherName: "",
    pinCode: "",
    languages: "",
    face: "",
    dob: "",
  };

  const [state, setState] = useSetState({
    data: initialValue,
    loading: false,
    buttonDisabled: false,
    deallocateModal: false,
    userId: "",
    driverId: "",
    vehicleAllocated: {
      vehicleId: "",
    },
  });

  const inputFields: IAddValues[] = [
    {
      label: "Name",
      key: isEditable ? "userId.fullName" : "fullName",
      type: "string",
    },
    {
      label: "Gender",
      key: isEditable ? "userId.gender" : "gender",
      type: "select",
      options: [
        {
          label: "Male",
          value: "Male",
        },
        {
          label: "Female",
          value: "Female",
        },
      ],
    },
    {
      label: "D.O.B",
      key: isEditable ? "userId.dob" : "dob",
      type: "birthdate",
    },
    {
      label: "Blood Group",
      key: isEditable ? "userId.bloodGroup" : "bloodGroup",
      type: "select",
      options: [
        {
          label: "A+",
          value: "A+",
        },
        {
          label: "A-",
          value: "A-",
        },
        {
          label: "B+",
          value: "B+",
        },
        {
          label: "B-",
          value: "B-",
        },
        {
          label: "O+",
          value: "O+",
        },
        {
          label: "O-",
          value: "O-",
        },
        {
          label: "AB+",
          value: "AB+",
        },
        {
          label: "AB-",
          value: "AB-",
        },
      ],
    },
    {
      label: "Mobile",
      key: isEditable ? "userId.mobile" : "mobile",
      type: "string",
      hide: isEditable ? true : false,
    },
    {
      label: "Whatsapp no.",
      key: isEditable ? "userId.wapNumber" : "wapNumber",
      type: "string",
    },
    {
      label: "Secondary no.",
      key: isEditable ? "userId.secondaryMobileNo" : "secondaryMobileNo",
      type: "string",
      optional: true,
    },
    {
      label: "Father's Name",
      key: isEditable ? "userId.fatherName" : "fatherName",
      type: "string",
    },
    {
      label: "State",
      key: isEditable ? "userId.address[0].state" : "state",
      type: "state",
    },
    {
      label: "City",
      key: isEditable ? "userId.address[0].city" : "city",
      type: "city",
    },
    {
      label: "Full address",
      key: isEditable ? "userId.address[0].fullAddress" : "fullAddress",
      type: "string",
    },
    {
      label: "Pincode",
      key: isEditable ? "userId.address[0].pinCode" : "pinCode",
      type: "string",
    },
    {
      label: "Languages",
      key: isEditable ? "userId.languages" : "languages",
      type: "array",
      optional: true,
    },
    {
      label: "Registration Type",
      key: isEditable
        ? "userId.driverRegistrationType"
        : "driverRegistrationType",
      type: "select",
      options: [
        {
          label: "DEDICATED",
          value: "DEDICATED",
        },
        {
          label: "FLOATING",
          value: "FLOATING",
        },
      ],
    },
    {
      label: "Profile photo (Document must be above 1 MB size) ",
      key: isEditable ? "userId.userImage" : "face",
      type: "file",
      file_bucket_url: FACE_BUCKET_URL,
    },
  ];

  const inputFiles: IAddValues[] = [
    // { label: "Aadhaar card no", key: "dAadhaarCardNumber", type: "string" },
    // { label: "Lisence no", key: "dDlNumber", type: "string" },
    // { label: "Pancard no", key: "dPanNumber", type: "string" },
    {
      label: "Aadhaar card front (Document must be above 1 MB size) ",
      key: "aadhaarCardFront",
      type: "file",
      file_bucket_url: AADHAAR_BUCKET_URL,
      secondaryKey: "private",
    },
    {
      label: "Aadhar card back (Document must be above 1 MB size) ",
      key: "aadhaarCardBack",
      type: "file",
      file_bucket_url: AADHAAR_BUCKET_URL,
      secondaryKey: "private",
    },
    {
      label: "Pancard front (Document must be above 1 MB size) ",
      key: "panCardFront",
      type: "file",
      file_bucket_url: PAN_BUCKET_URL,
      secondaryKey: "private",
      optional: true,
    },
    {
      label: "Pancard back (Document must be below 1 MB size) ",
      key: "panCardBack",
      type: "file",
      file_bucket_url: PAN_BUCKET_URL,
      secondaryKey: "private",
      optional: true,
    },
    {
      label: "Driving liscence front (Document must be above 1 MB size) ",
      key: "dlFront",
      type: "file",
      file_bucket_url: DL_BUCKET_URL,
      secondaryKey: "private",
    },
    {
      label: "Driving liscence back (Document must be below 1 MB size) ",
      key: "dlBack",
      type: "file",
      file_bucket_url: DL_BUCKET_URL,
      secondaryKey: "private",
    },
  ];
  const privateInfo: IAddValues[] = [
    {
      label: "Aadhaar card no",
      key: "dAadhaarCardNumber",
      type: "string",
    },
    {
      label: "Lisence no",
      key: "dDlNumber",
      type: "string",
    },
    {
      label: "Pancard no",
      key: "dPanNumber",
      type: "string",
      optional: true,
    },
  ];
  const inputBankDetails: IAddValues[] = [
    {
      label: "Account holder's name",
      key: isEditable ? "bankDetails.acHolderName" : "acHolderName",
      type: "string",
    },
    {
      label: "Account no.",
      key: isEditable ? "bankDetails.acNumber" : "acNumber",
      type: "string",
    },
    {
      label: "IFSC Code",
      key: isEditable ? "bankDetails.ifscCode" : "ifscCode",
      type: "string",
    },
    {
      label: "Passbook front (Document must be below 1 MB size) ",
      key: isEditable ? "bankDetails.passbookFront" : "passbookFront",
      type: "file",
      file_bucket_url: PASSBOOK_BUCKET_URL,
      secondaryKey: "private",
    },
    {
      label: "Deposited amount",
      key: "driverDeposit",
      type: "string",
    },
    {
      label: "Driver deposit remarks",
      key: "driverDepositRemarks",
      type: "string",
    },
  ];
  const allocateVehicle: IAddValues[] = [
    {
      label: "Allot a vehicle",
      key: "vehicleId",
      type: "vehicle",
      optional: true,
    },
  ];
  const inputEmergency: IAddValues[] = [
    {
      label: "Relation",
      key: isEditable ? "emergencyDetails.relation" : "relation",
      type: "select",
      options: [
        {
          label: "Father",
          value: "Father",
        },
        {
          label: "Mother",
          value: "Mother",
        },
        {
          label: "Spouse",
          value: "Spouse",
        },
        {
          label: "Friend",
          value: "Friend",
        },
      ],
    },
    {
      label: "Contact Name",
      key: isEditable ? "emergencyDetails.name" : "name",
      type: "string",
    },
    {
      label: "Mobile",
      key: isEditable ? "emergencyDetails.mobile" : "mobile",
      type: "string",
    },
    {
      label: "City",
      key: isEditable ? "emergencyDetails.city" : "city",
      type: "string",
    },
    {
      label: "Address",
      key: isEditable ? "emergencyDetails.address" : "address",
      type: "string",
    },
    {
      label: "Pincode",
      key: isEditable ? "emergencyDetails.pinCode" : "pinCode",
      type: "string",
    },
  ];

  useEffect(() => {
    if (isEditable) {
      GetDriver();
    }
  }, []);
  useEffect(() => {
    if (isEditable) {
      getAllocatedVehicle();
    }
  }, [state.did]);

  const GetDriver = async () => {
    try {
      const response: any = await Models.driver.getDriver({
        id: params.id,
      });
      setState({
        data: response.data[0],
        did: response.data[0]._id,
        vehicleId: response.data[0].vehicleId,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditBasic = async (values) => {
    try {
      let data: any = removeEmptyValues(
        createPayloadUpdate(values.userId, [
          "geoLocation",
          "isDefault",
          "registrationMethod",
          "_id",
          "userImage",
          "createdAt",
          "languages",
          "approvedBy",
        ])
      );
      data = simplifyJSON(data);
      data._id = values.userId._id;
      data.face = values.userId.userImage;
      data.languages = values.userId.languages;
      //filtered DATA
      let final_data = {
        driverRegistrationType: data.driverRegistrationType,
        fullName: data.fullName,
        gender: data.gender,
        fullAddress: data.fullAddress,
        state: data.state,
        city: data.city,
        pinCode: data.pinCode,
        fatherName: data.fatherName,
        dob: data.dob,
        wapNumber: data.wapNumber,
        secondaryMobileNo: data.secondaryMobileNo,
        bloodGroup: data.bloodGroup,
        mobile: data.mobile,
        _id: data._id,
        face: data.face,
        languages: data.languages,
      };

      const formData = jsonToFormData(final_data);
      await Models.driver.editDriverBasic(formData);
      toastify("Basic info updated");
    } catch (err) {
      toastifyError(err);
      setState({ buttonDisabled: false });
      console.log(err);
    }
  };

  const handleEditUpload = async (values) => {
    try {
      const data: any = createPayloadUpdate(removeEmptyValues(values), [
        "userId",
        "vehicleAllocationStatus",
        "bankDetails",
        "emergencyDetails",
        "dNumber",
        "_id",
        "createdAt",
      ]);
      // data._id = values.userId._id;
      let final_data = {
        aadhaarCardFront: data.aadhaarCardFront,
        aadhaarCardBack: data.aadhaarCardBack,
        panCardFront: data.panCardFront,
        panCardBack: data.panCardBack,
        dlFront: data.dlFront,
        _id: values.userId._id,
      };
      const formData = jsonToFormData(final_data);
      await Models.driver.editDriverUpload(formData);
      toastify("Documents uploaded");
    } catch (err) {
      toastifyError(err);
      setState({ buttonDisabled: false });
      console.log(err);
    }
  };
  const handleEditBankDetails = async (values) => {
    try {
      const data: any = removeEmptyValues(values.bankDetails);
      data._id = values.userId._id;
      data.driverId = values._id;
      data.driverDeposit = values.driverDeposit;
      data.driverDepositRemarks = values.driverDepositRemarks;

      let final_data = {
        acHolderName: data.acHolderName,
        acNumber: data.acNumber,
        ifscCode: data.ifscCode,
        passbookFront: data.passbookFront,
        _id: data._id,
        driverId: data.driverId,
        driverDeposit: data.driverDeposit,
        driverDepositRemarks: data.driverDepositRemarks,
      };
      const formData = jsonToFormData(final_data);
      await Models.driver.editDriverBankDetails(formData);
      toastify("Bank details updated");
    } catch (err) {
      toastifyError(err);
      setState({ buttonDisabled: false });
      console.log(err);
    }
  };

  const handleEditEmergency = async (values) => {
    try {
      const data: any = removeEmptyValues(values.emergencyDetails);
      let final_data = {
        address: data.address,
        city: data.city,
        driverId: values._id,
        mobile: data.mobile,
        name: data.name,
        pinCode: data.pinCode,
        relation: data.relation,
        _id: values.userId._id,
      };
      await Models.driver.editDriverEmergency(final_data);
      toastify("Emergency contact updated");
      navigate("/driver");
    } catch (err) {
      toastifyError(err);
      setState({ buttonDisabled: false });
      console.log(err);
    }
  };
  const handleCreateBasic = async (values) => {
    try {
      console.log(values);

      const data: any = removeEmptyValues(values);
      const formData = jsonToFormData(data);
      const res: any = await Models.driver.createDriverBasic(formData);
      setState({ userId: res.data._id });
      toastify("Basic info uploaded");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const handleCreateUpload = async (values) => {
    try {
      values._id = state.userId;
      const data: any = removeEmptyValues(values);
      const formData = jsonToFormData(data);
      const res: any = await Models.driver.createDriverUpload(formData);
      setState({ driverId: res.data._id });
      toastify("Documents uploaded");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const handleAllotVehicleToDriver = async (values) => {
    // console.log(values);
    console.log("DID", isEditable ? state.did : state.driverId);
    try {
      const body: any = {
        vehicleId: values.vehicleId,
        driverId: isEditable ? state.did : state.driverId,
      };
      const res: any = await Models.driver.allotVehicleToDriver(body);
      // setState({ userId: res.data._id });
      toastify("Driver Allocated successfully!");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const getAllocatedVehicle = async () => {
    if (state.did) {
      try {
        let res: any;
        const body: any = {
          dId: state.did,
        };
        res = await Models.vehicle.getAllocatedDriver(body);
        setState({
          vehicleAllocated: { vehicleId: res.data[0]?.vehicleId?._id },
        });
      } catch (error) {
        setState({ loading: false });
        console.log(error);
        toastifyError(error);
      }
    }
  };
  const handleCreateBankDetails = async (values) => {
    try {
      values._id = state.userId;
      values.driverId = state.driverId;
      const data: any = removeEmptyValues(values);
      const formData = jsonToFormData(data);
      const res: any = await Models.driver.createDriverBankDetails(formData);
      toastify("Bank details uploaded!");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const handleCreatePersonalInfo = async (values) => {
    console.log(values);

    try {
      // values._id = state.driverId;
      const data: any = removeEmptyValues(values);
      const jsonString = JSON.stringify(data);
      const payload = await encrypt(jsonString);
      const body = {
        _id: state.driverId,
        data: payload,
      };
      const res: any = await Models.driver.createPersonalInfo(body);
      toastify("Driver personal info uploaded!");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const handleCreateEmergency = async (values) => {
    try {
      values.driverId = state.driverId;
      const data: any = removeEmptyValues(values);
      const res: any = await Models.driver.createDriverEmergency(data);
      navigate("/driver");
      toastify("Driver onboarded successfully!");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  return (
    <div className="add_driver_container">
      <div className="add_driver_wrapper">
        <History
          name={state?.data?.userId?.fullName}
          path={isEditable ? "Driver/edit" : "Driver/add"}
        />
        <div className="add_driver_body_container">
          <h4>Personal Information</h4>
          <Add
            title={`${isEditable ? "Edit" : "Add"}`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputFields}
            initialValues={initialValue}
            validationSchema={isEditable ? "" : "driverBasic"}
            onSubmit={isEditable ? handleEditBasic : handleCreateBasic}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
        {/* <Divider /> */}
        <div className="add_driver_body_container">
          <h4>Personal Documents</h4>
          <Add
            title={`${isEditable ? "Edit" : "Add"}`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputFiles}
            initialValues={initialValue}
            validationSchema={""}
            onSubmit={isEditable ? handleEditUpload : handleCreateUpload}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
        {!isEditable && (
          <div className="add_driver_body_container">
            <h4>Unique Identifiers</h4>
            <AddVerification
              data={state.data}
              values={privateInfo}
              onSubmit={handleCreatePersonalInfo}
              id={state.driverId}
            />
          </div>
        )}
        <div className="add_driver_body_container">
          <h4>Vehicle Allocation</h4>
          <Add
            title={`${isEditable ? "Edit" : "Add"}`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.vehicleAllocated}
            values={allocateVehicle}
            initialValues={""}
            validationSchema={""}
            onSubmit={handleAllotVehicleToDriver}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
        {/* <Divider /> */}
        <div className="add_driver_body_container">
          <h4>Bank Account Details</h4>
          <Add
            title={`${isEditable ? "Edit" : "Add"}`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputBankDetails}
            initialValues={initialValue}
            validationSchema={""}
            onSubmit={
              isEditable ? handleEditBankDetails : handleCreateBankDetails
            }
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
        {/* <Divider /> */}
        <div className="add_driver_body_container">
          <h4>Emergency Contact</h4>
          <Add
            title={`${isEditable ? "Edit" : "Add"}`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputEmergency}
            initialValues={initialValue}
            validationSchema={""}
            onSubmit={isEditable ? handleEditEmergency : handleCreateEmergency}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
      </div>
    </div>
  );

  function AddButton(props: any) {
    return (
      <div className="view_button_container">
        <div className="view_button_wrapper">
          {isEditable ? (
            <div className="view_button">
              <Button
                width="250px"
                buttonDisabled={props.buttonDisabled}
                value="Update"
              />
            </div>
          ) : (
            <div className="view_button">
              <Button
                width="250px"
                buttonDisabled={props.buttonDisabled}
                value="Save"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
// import "./view_checkin.screen.scss";
import History from "components/history/history.component";
import View from "components/view/view.component";
import Button from "common_components/ui/button/button.ui";
import Assets from "imports/assets.import";
import { Models } from "utils/imports.utils";
import { useSetState, toastifyError } from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";
import DeletePopup from "components/delete_popup/delete_popup.component";
import CustomModal from "common_components/ui/modal/modal.component";
import { useNavigate } from "react-router-dom";
import { VEHICLE_IMAGE_UPLOAD_URL } from "utils/constant.utils";

export default function ViewCheckin() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, setState] = useSetState({
    data: {},
    deleteModal: false,
  });
  useEffect(() => {
    GetCheckin();
  }, []);

  const GetCheckin = async () => {
    try {
      const response: any = await Models.checkin.getChecin({
        id: id,
      });
      setState({ data: response.data[0] });
    } catch (err) {
      console.log(err);
    }
  };

  const inputFields: IAddValues[] = [
    {
      label: "Driver contact",
      key: "createdBy.mobile",
      type: "string",
      isNested: true,
    },
    {
      label: "Vehicle type",
      key: "vehicleId.vhType",
      type: "string",
      isNested: true,
    },
    {
      label: "Vehicle model",
      key: "vehicleId.vhModel",
      type: "string",
      isNested: true,
    },
    {
      label: "Vehicle number",
      key: "vehicleId.vhNumber",
      type: "string",
      isNested: true,
    },
    {
      label: "Vehicle ignition",
      key: "vehicleId.isIgnitionOn",
      type: "boolean",
      isNested: true,
    },
    { label: "State", key: "state", type: "string" },
    { label: "City", key: "city", type: "string" },
    { label: "Checkin time", key: "checkInTime", type: "date" },
    { label: "Checkout time", key: "checkOutTime", type: "date" },
    { label: "Checkin Status", key: "checkIn", type: "boolean" },
    {
      label: "Store name",
      key: "storeId.storeName",
      type: "string",
      isNested: true,
    },
    {
      label: "Store address",
      key: "storeId.fullAddress",
      type: "string",
      isNested: true,
    },
    {
      label: "Store ID",
      key: "storeId.storeId",
      type: "string",
      isNested: true,
    },
    {
      label: "Vehicle-Driver selfie",
      key: "vehicleDriverSelfie",
      type: "file",
      file_bucket_url: VEHICLE_IMAGE_UPLOAD_URL,
    },
    {
      label: "Vehicle pickup image",
      key: "vehiclePickupImage",
      type: "file",
      file_bucket_url: VEHICLE_IMAGE_UPLOAD_URL,
    },
    {
      label: "Vehicle drop image",
      key: "vehicleDropImage",
      type: "file",
      file_bucket_url : VEHICLE_IMAGE_UPLOAD_URL
    },
  ];

  return (
    <div className="view_checkin_container">
      <div className="view_checkin_wrapper">
        <History name={state?.data?.createdBy?.fullName} path="view" />
        <div className="view_checkin_body_container">
          <View
            actions={[]}
            data={state.data}
            values={inputFields}
            // buttons={<ViewButton />}
            head={<ViewHeader />}
            hasFiles
          />
        </div>
      </div>
    </div>
  );
  function ViewHeader() {
    return (
      <div className="view_head_left_container">
        <div className="view_head_image_conatiner">
          <img
            className="view_head_image"
            src={state?.data?.vehicleDriverSelfie || Assets.testPic}
            alt="head_image"
          />
        </div>
        <div className="view_head_title_container">
          <div className="view_head_title_wrapper">
            <div className="view_head_title">
              {state?.data?.createdBy?.fullName}
            </div>
            <div className="view_head_sub_title h5">{`DNumber - ${
              state?.data?.driverId?.dNumber || ""
            }`}</div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { useEffect } from "react";
import History from "components/history/history.component";
import Add from "components/add/add.component";
import Assets from "imports/assets.import";
import Button from "common_components/ui/button/button.ui";
import "./add_vehicle.screen.scss";
import UploadButton from "common_components/ui/upload_button/upload_button.ui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  removeEmptyValues,
  useSetState,
  toastifyError,
  jsonToFormData,
  simplifyJSON,
  createPayloadUpdate,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import { IAddValues } from "utils/interface.utils";
import { RC_DOC_UPLOAD_URL } from "utils/constant.utils";

export default function AddVehicle() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const pathArr = pathname.split("/");
  const isEditable = pathArr.includes("edit");

  const initialValue = {
    vhModel: "",
    city: "",
    state: "",
    vhType: "",
    ownerName: "",
    isOwnVehicle: false,
    vhNumber: "",
    vhCompany: "",
    rcNumber: "",
    insuranceNumber: "",
    vhImage: "",
    rcImageF: "",
    rcImageB: "",
    insuranceImage: "",
  };
  const selectOption = [{ label: "Test", value: "test" }];

  const [state, setState] = useSetState({
    data: initialValue,
    loading: false,
    selectOptions: selectOption,
    buttonDisabled: false,
  });

  const inputFieldsWorking: IAddValues[] = [
    { label: "Model", key: "vhModel", type: "string" },
    { label: "State", key: "state", type: "state" },
    { label: "City", key: "city", type: "city" },
    {
      label: "Type",
      key: "vhType",
      options: [
        { label: "2W", value: "2W" },
        { label: "3W", value: "3W" },
        { label: "4W", value: "4W" },
      ],
      type: "select",
    },
    { label: "Owner Name", key: "ownerName", type: "string" },
    {
      label: "Own vehicle",
      key: "isOwnVehicle",
      type: "select",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      optional: true,
    },
    { label: "Vehicle Number", key: "vhNumber", type: "string" },
    { label: "Vehicle Company", key: "vhCompany", type: "string" },
    { label: "RC Number", key: "rcNumber", type: "string" },
    { label: "Insurance Number", key: "insuranceNumber", type: "string" },
    {
      label: "Bike image (Document must be above 1 MB size) ",
      key: "vhImage",
      type: "file",
      file_bucket_url: RC_DOC_UPLOAD_URL,
      secondaryKey: "private",
    },
    {
      label: "RC book front (Document must be above 1 MB size) ",
      key: "rcImageF",
      type: "file",
      file_bucket_url: RC_DOC_UPLOAD_URL,
      secondaryKey: "private",
    },
    {
      label: "RC book back (Document must be above 1 MB size) ",
      key: "rcImageB",
      type: "file",
      file_bucket_url: RC_DOC_UPLOAD_URL,
      secondaryKey: "private",
    },
    {
      label: "Insurance image (Document must be above 1 MB size) ",
      key: "insuranceImage",
      type: "file",
      file_bucket_url: RC_DOC_UPLOAD_URL,
      secondaryKey: "private",
    },
  ];

  useEffect(() => {
    if (isEditable) {
      GetVehicle();
    }
  }, []);

  const GetVehicle = async () => {
    try {
      const response: any = await Models.vehicle.getVehicle({
        id: params.id,
      });
      setState({ data: response.data.data[0] });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const handleEdit = async (values) => {
    setState({ buttonDisabled: true });
    try {
      const data = createPayloadUpdate(
        simplifyJSON(removeEmptyValues(values)),
        [
          "createdAt",
          "email",
          "updatedAt",
          "vId",
          "_id",
          "vehicle_id",
          "allocationStatus",
        ]
      );

      data._id = params.id;
      const formdata = jsonToFormData(data);
      console.log("vehicle edit values", formdata);

      await Models.vehicle.editVehicle(formdata);
      navigate("/vehicle");
    } catch (err) {
      setState({ buttonDisabled: false });
      console.log(err);
      toastifyError(err);
    }
  };

  const handleCreate = async (values) => {
    // setState({ buttonDisabled: true });
    console.log(values);

    try {
      values.vhTrackingSystemProvider = "INTELLICAR";
      const data: any = jsonToFormData(removeEmptyValues(values));
      await Models.vehicle.createVehicle(data);
      navigate("/vehicle");
    } catch (err) {
      // setState({ buttonDisabled: false });
      console.log(err);
      toastifyError(err);
    }
  };

  return (
    <div className="add_vehicle_container">
      <div className="add_vehicle_wrapper">
        <History
          name={state?.data?.vhModel}
          path={isEditable ? "Vehicle/edit" : "Vehicle/add"}
        />
        <div className="add_vehicle_body_container">
          <Add
            title={`${isEditable ? "Edit" : "Add"} Vehicle`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputFieldsWorking}
            initialValues={initialValue}
            validationSchema="vehicle"
            onSubmit={isEditable ? handleEdit : handleCreate}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
      </div>
    </div>
  );

  function AddButton(props: any) {
    return (
      <div className="view_button_container">
        <div className="view_button_wrapper">
          {isEditable ? (
            <div className="view_button">
              <Button buttonDisabled={props.buttonDisabled} value="Update" />
            </div>
          ) : (
            <div className="view_button">
              <Button buttonDisabled={props.buttonDisabled} value="Save" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

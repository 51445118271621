import React, { FC, Suspense, useEffect } from "react";
import "./App.scss";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Main from "common_components/hoc/main.hoc";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
import Auth from "screens/auth/auth.screen";
import Driver from "screens/driver/driver.screen";
import ViewDriver from "screens/driver/view_driver.screen";
import AddDriver from "screens/driver/add_driver.screen";
import Dashboard from "screens/dashboard/dashboard.screen";
import Organization from "screens/organization/organization.screen";
import AddOrganization from "screens/organization/add_organization.screen";
import ViewOrganization from "screens/organization/view_organization.screen";
import Vehicle from "screens/vehicle/vehicle.screen";
import ViewVehicle from "screens/vehicle/view_vehicle.screen";
import AddVehicle from "screens/vehicle/add_vehicle.screen";
import ProtectedRoutes from "common_components/hoc/protect.routes.hoc";
import GuestRoutes from "common_components/hoc/guest.routes.hoc";
import NotFound from "screens/not_found/not_found.screen";
import Store from "screens/store/store.screen";
import ViewStore from "screens/store/view_store.screen";
import AddStore from "screens/store/add_store.screen";
import Center from "screens/control_center/center.screen";
import ViewCenter from "screens/control_center/view_center.screen";
import AddCenter from "screens/control_center/add_center.screen";
import Checkin from "screens/checkin/checkin.screen";
import ViewCheckin from "screens/checkin/view_checkin.screen";
import User from "screens/user/user.screen";
import ViewUser from "screens/user/view_user";
import EditUser from "screens/user/edit_user";
import ClientPOV from "common_components/hoc/client.pov";
import AdminPOV from "common_components/hoc/admin.pov";
import ClientPovDashboard from "screens/client_pov/client_pov_dashboard/client.pov.dashboard";
import ClientPovStore from "screens/client_pov/client_pov_store/client.pov.store";
import ClientPovStoreAuthWrapper from "screens/client_pov/client_pov_store/client.pov.store.auth.wrapper";
import Ticket from "screens/ticket/ticket.screen";
import ViewTicket from "screens/ticket/view_ticket.screen";
import ViewProfile from "screens/user_profile/view_profile";
import EditProfile from "screens/user_profile/edit_profile";
// import store from "store";
// import { getUser } from "store/actions/auth.actions";


export const App: FC<any> = () => {
  // useEffect(()=>{
  //   store.dispatch(getUser());
  // },[])
  // if(store.getState().auth)
  return (
    <>
      <ToastContainer position="top-center" transition={Slide} />
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <Routes>
            {/* Guest Routes */}
            <Route element={<GuestRoutes />}>
              <Route path="/" element={<Auth />}></Route>
              <Route path="/sign-up" element={<Auth />} />
              <Route path="/sign-up/reset_password" element={<Auth />}></Route>
              <Route path="/sign-up/forgot_password" element={<Auth />}></Route>
              <Route
                path="/sign-up/reset_password_confirm"
                element={<Auth />}
              />
            </Route>

            {/* Protected Routes */}
            <Route element={<ProtectedRoutes />}>
              <Route element={<ClientPOV />}>
                <Route
                  path="/client-pov/dashboard"
                  element={<ClientPovDashboard />}
                />
                <Route
                  path="/client-pov/store"
                  element={<ClientPovStoreAuthWrapper />}
                />
                <Route
                  path="/client-pov/:clientId/store/view/:id"
                  element={<ViewStore />}
                ></Route>
                {/* <Route
                  path="/client-pov/:clientId/store/edit/:id"
                  element={<AddStore />}
                ></Route>
                <Route
                  path="/client-pov/:clientId/store/add"
                  element={<AddStore />}
                ></Route> */}
              </Route>
              <Route element={<AdminPOV />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/profile" element={<ViewProfile />} />
                <Route path="/profile/edit" element={<EditProfile />} />
                <Route path="/user" element={<User />} />
                <Route path="/user/view/:id" element={<ViewUser />} />
                <Route path="/user/add" element={<EditUser />} />
                <Route path="/user/edit/:id" element={<EditUser />} />
                <Route path="/driver" element={<Driver />} />
                <Route path="/driver/view/:id" element={<ViewDriver />} />
                <Route path="/driver/edit/:id" element={<AddDriver />} />
                <Route path="/driver/add" element={<AddDriver />} />
                <Route path="/client" element={<Organization />} />
                <Route path="/client/view/:id" element={<ViewOrganization />} />
                <Route path="/client/edit/:id" element={<AddOrganization />} />
                <Route path="/client/add" element={<AddOrganization />} />
                <Route
                  path="/client/:clientId/store"
                  element={<Store />}
                ></Route>
                <Route
                  path="/client/:clientId/store/view/:id"
                  element={<ViewStore />}
                ></Route>
                <Route
                  path="/client/:clientId/store/edit/:id"
                  element={<AddStore />}
                ></Route>
                <Route
                  path="/client/:clientId/store/add"
                  element={<AddStore />}
                ></Route>
                <Route path="/vehicle" element={<Vehicle />} />
                <Route path="/vehicle/view/:id" element={<ViewVehicle />} />
                <Route path="/vehicle/edit/:id" element={<AddVehicle />} />
                <Route path="/vehicle/add" element={<AddVehicle />} />
                <Route path="/center" element={<Center />} />
                <Route path="/center/view/:id" element={<ViewCenter />} />
                <Route path="/center/edit/:id" element={<AddCenter />} />
                <Route path="/center/add" element={<AddCenter />} />
                <Route path="/checkin" element={<Checkin />}></Route>
                <Route path="/checkin/view/:id" element={<ViewCheckin />} />
                <Route path="/checkin/:id" element={<Checkin />}></Route>
                {/* <Route path="/ticket" element={<Ticket />}></Route>
                <Route path="/ticket/view/:id" element={<ViewTicket />}></Route> */}
              </Route>

              {/* 404 page for dashboard pages */}
              <Route path="/*" element={<NotFound />} />
            </Route>
          </Routes>
          <Toaster
            position="bottom-right"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 3000,
              style: {
                background: "#363636",
                color: "#fff",
              },
              success: {
                duration: 3000,
                iconTheme: {
                  primary: "green",
                  secondary: "black",
                },
              },
            }}
          />
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;

import { useNavigate } from "react-router-dom";
import {
  useSetState,
  toastifyError,
  toastify,
  createExcelFile,
} from "utils/functions.utils";
import Search from "common_components/ui/search/search.ui";
import Button from "common_components/ui/button/button.ui";
import Table from "components/table/table.component";
import Pagination from "components/pagination/pagination.component";
import Models from "imports/models.import";
import { useEffect } from "react";
import _ from "lodash";
import CustomModal from "common_components/ui/modal/modal.component";
import TextareaComponent from "common_components/ui/text_area/textarea.component";
import pako from "pako";
import { Buffer } from "buffer";
import Colors from "imports/color.import";
import DeletePopup from "components/delete_popup/delete_popup.component";

export default function User() {
  const navigate = useNavigate();
  const [state, setState] = useSetState({
    search: "",
    data: [],
    toggleFilter: false,
    currentPage: 1,
    totalItems: 0,
    activeTab: "",
    selectedFilter: "ls",
    approvalModal: false,
    rejectModal: false,
    comment: "",
    archiveModal: false,
    terminateModal: false,
    resignModal: false,
    unarchiveModal: false,
    resignComment: "",
    terminationComment: "",
  });
  const role = localStorage.getItem("role");
  const thead = [
    { head: "User image & name", key: "fullName" },
    { head: "Mobile", key: "mobile", type: "string" },
    { head: "Role", key: "role", type: "array" },
    { head: "Email", key: "email", type: "string" },
  ];
  const GetManyData = async () => {
    try {
      let res: any;
      const body: any = {
        page: state.currentPage,
        pageSize: 10,
      };
      if (state.search.length > 0) {
        body.search = state.search;
      }
      if (state.selectedFilter.length > 0) {
        if (state.selectedFilter === "wt" || state.selectedFilter === "ls")
          body.adm = state.selectedFilter;
        else body.status = state.selectedFilter;
      }
      if (state.dateRange) {
        body.startDate = state.dateRange[0];
        body.endDate = state.dateRange[1];
      }
      res = await Models.user.getManyUser(body);
      setState({
        totalItems: res?.count,
        data: res?.data,
      });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };

  useEffect(() => {
    GetManyData();
  }, [
    state.search,
    state.currentPage,
    state.activeTab,
    state.selectedFilter,
    state.dateRange,
  ]);

  const handlePageChange = (page: number) => {
    setState({ currentPage: page });
  };
  const handleView = (data) => {
    navigate(`/user/view/${data?._id}`);
  };
  const handleFilter = (status) => {
    setState({ selectedFilter: status, currentPage: 1, totalItems: 0 });
  };
  const handleApproval = (data: any) => {
    setState({ user_id: data._id, approvalModal: true });
  };
  const handleArchive = (data) => {
    setState({ id: data._id, archiveModal: true });
  };
  const handleTerminate = (data) => {
    setState({ id: data._id, terminateModal: true });
  };
  const handleResign = (data) => {
    setState({ id: data._id, resignModal: true });
  };
  const handleUnarchive = (data) => {
    setState({ id: data._id, unarchiveModal: true });
  };
  const handleApproveStatus = async (uId) => {
    try {
      await Models.user.approveUser(uId);
      GetManyData();
      toastify("User status updated");
      setState({
        approvalModal: false,
      });
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const handleRejectStatus = async (uId) => {
    if (!_.isEmpty(state.comment)) {
      try {
        const body = {
          uId,
          comment: state.comment,
        };
        await Models.user.rejectUser(body);
        GetManyData();
        toastify("User status updated");
        setState({
          rejectModal: false,
          comment: "",
        });
      } catch (err) {
        toastifyError(err);
        console.log(err);
      }
    } else {
      toastifyError("Please enter a reasons");
    }
  };
  const handleResignStatus = async () => {
    if (!_.isEmpty(state.resignComment)) {
      try {
        const body = {
          id: state.id,
          comment: state.resignComment,
          status: "RESIGNED",
        };
        // console.log("RESIGN COMMENT",state.resignComment);

        await Models.user.resignTerminateUser(body);
        GetManyData();
        toastify("User status updated");
        setState({
          resignModal: false,
          resignComment: "",
        });
      } catch (err) {
        toastifyError(err);
        console.log(err);
      }
    } else {
      toastifyError("Please enter a reason");
    }
  };
  const handleTerminationStatus = async () => {
    if (!_.isEmpty(state.terminationComment)) {
      try {
        const body = {
          id: state.id,
          comment: state.terminationComment,
          status: "TERMINATED",
        };
        await Models.user.resignTerminateUser(body);
        GetManyData();
        toastify("User status updated");
        setState({
          terminateModal: false,
          terminationComment: "",
        });
      } catch (err) {
        toastifyError(err);
        console.log(err);
      }
    } else {
      toastifyError("Please enter a reason");
    }
  };
  const ArchiveUser = async () => {
    try {
      await Models.user.deleteUser({
        ids: [state.id],
        target: "archive",
        checkCreatedBy: false,
        hardDelParam: false,
        reverterParam: false,
        createdByCheck: false,
      });
      setState({ id: "", archiveModal: false });
      GetManyData();
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const UnarchiveUser = async () => {
    try {
      await Models.user.deleteUser({
        ids: [state.id],
        target: "archive",
        checkCreatedBy: false,
        hardDelParam: false,
        reverterParam: true,
        createdByCheck: false,
      });
      setState({ id: "", unarchiveModal: false });
      GetManyData();
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const handleExport = async () => {
    const res: any = await Models.user.exportUser();
    const compressedString = res.data;
    const compressedBuffer = Buffer.from(compressedString, "base64");
    const base64String = pako.ungzip(compressedBuffer, { to: "base64" });
    createExcelFile(base64String);
  };
  return (
    <div className="driver_screen">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="head h3">User</div>
        </div>
        <div className="search_wrapper">
          <Search
            value={state.search}
            onChange={(search) => setState({ search })}
            placeholder={"Search by Name, Email, Number"}
          />

          <div className="button_group">
            {/* <Filter
              onClick={() => setState({ toggleFilter: !state.toggleFilter })}
            /> */}
            {role === "SA" && (
              <Button
                value="Add user"
                onClick={() => {
                  navigate("/user/add");
                }}
              />
            )}
            <Button
              value="Export"
              onClick={() => {
                handleExport();
              }}
            />
          </div>
        </div>
      </div>
      <div className="driver_filters">
        <div
          onClick={() => handleFilter("ls")}
          className={`button ${
            "ls" === state.selectedFilter ? "selected" : ""
          }`}
        >
          Approved
        </div>
        <div
          onClick={() => handleFilter("wt")}
          className={`button ${
            "wt" === state.selectedFilter ? "selected" : ""
          }`}
        >
          Requested
        </div>
        <div
          onClick={() => handleFilter("RESIGNED")}
          className={`button ${
            "RESIGNED" === state.selectedFilter ? "selected" : ""
          }`}
        >
          Resigned
        </div>
        <div
          onClick={() => handleFilter("TERMINATED")}
          className={`button ${
            "TERMINATED" === state.selectedFilter ? "selected" : ""
          }`}
        >
          Terminated
        </div>
        <div
          onClick={() => handleFilter("ARCHIVED")}
          className={`button ${
            "ARCHIVED" === state.selectedFilter ? "selected" : ""
          }`}
        >
          Archived
        </div>
      </div>
      <div className="drivertable">
        <Table
          data={state.data}
          totalDocs={state.totalDocs}
          loading={state.loading}
          theads={thead}
          link=""
          loadMore={() => {}}
          actions={
            role === "SA"
              ? state.selectedFilter === "ls"
                ? [
                    {
                      icon: "view",
                      onClick: handleView,
                    },
                    {
                      icon: "archive",
                      onClick: handleArchive,
                    },
                    {
                      icon: "delete",
                      text: "Terminate",
                      textBackground: Colors.red,
                      hideIcon: true,
                      onClick: handleTerminate,
                    },
                    {
                      icon: "delete",
                      text: "Resign",
                      textBackground: Colors.red,
                      hideIcon: true,
                      onClick: handleResign,
                    },
                  ]
                : state.selectedFilter === "wt"
                ? [
                    {
                      icon: "view",
                      onClick: handleView,
                    },
                    {
                      icon: "delete",
                      text: "Approve",
                      textBackground: Colors.primary,
                      hideIcon: true,
                      onClick: handleApproval,
                    },
                    {
                      icon: "archive",
                      onClick: handleArchive,
                    },
                  ]
                : state.selectedFilter === "RESIGNED" ||
                  state.selectedFilter === "TERMINATED"
                ? [
                    {
                      icon: "view",
                      onClick: handleView,
                    },
                  ]
                : state.selectedFilter === "ARCHIVED"
                ? [
                    {
                      icon: "upload",
                      onClick: handleUnarchive,
                    },
                    {
                      icon: "view",
                      onClick: handleView,
                    },
                  ]
                : []
              : [
                  {
                    icon: "view",
                    onClick: handleView,
                  },
                ]
          }
          imageKey="userImage"
          img={true}
        />
      </div>
      <div className="driver_pagination">
        <Pagination
          totalItems={state.totalItems}
          itemsPerPage={10}
          currentPage={state.currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      {/* approve modal */}
      <CustomModal
        center
        open={state.approvalModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ approvalModal: false })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">
            Are you sure you want to approve?
          </div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ approvalModal: false });
              }}
              className="reject_cancel_button"
            >
              Cancel
            </div>
            <div
              onClick={() => handleApproveStatus(state.user_id)}
              className="reject_button"
            >
              Approve
            </div>
          </div>
        </div>
      </CustomModal>
      {/* approve modal end */}
      {/* reject Modal */}
      <CustomModal
        center
        open={state.rejectModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ rejectModal: false, comment: "" })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">Reason for rejection</div>
          <div className="rejection_document_wrapper">
            <TextareaComponent
              placeholder="Enter rejection reasons"
              value={state.comment}
              onChange={(comment: any) => setState({ comment })}
            />
          </div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ rejectModal: false, comment: "" });
              }}
              className="reject_cancel_button"
            >
              Cancel
            </div>
            <div
              onClick={() => handleRejectStatus(state.user_id)}
              className="reject_button"
            >
              Save
            </div>
          </div>
        </div>
      </CustomModal>
      {/* reject modal end */}
      {/* For Resign modal start*/}
      <CustomModal
        center
        open={state.resignModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ resignModal: false, resignComment: "" })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">Reason for resign</div>
          <div className="rejection_document_wrapper">
            <TextareaComponent
              placeholder="Enter resignation reason."
              value={state.resignComment}
              onChange={(resignComment: any) => setState({ resignComment })}
            />
          </div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ resignModal: false, resignComment: "" });
              }}
              className="reject_cancel_button"
            >
              Cancel
            </div>
            <div onClick={handleResignStatus} className="reject_button">
              Save
            </div>
          </div>
        </div>
      </CustomModal>
      {/* For Resign modal start*/}
      {/* For Terminate modal start*/}
      <CustomModal
        center
        open={state.terminateModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() =>
          setState({ terminateModal: false, terminationComment: "" })
        }
      >
        <div className="reject_modal_container">
          <div className="reject_heading">Reason for termination</div>
          <div className="rejection_document_wrapper">
            <TextareaComponent
              placeholder="Enter termination reason."
              value={state.terminationComment}
              onChange={(terminationComment: any) =>
                setState({ terminationComment })
              }
            />
          </div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ terminateModal: false, terminationComment: "" });
              }}
              className="reject_cancel_button"
            >
              Cancel
            </div>
            <div onClick={handleTerminationStatus} className="reject_button">
              Save
            </div>
          </div>
        </div>
      </CustomModal>
      {/* For Terminate modal start*/}
      <CustomModal
        center
        open={state.archiveModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ archiveModal: false })}
      >
        <DeletePopup
          onPress={ArchiveUser}
          onCancel={() => setState({ archiveModal: false })}
        />
      </CustomModal>
      <CustomModal
        center
        open={state.unarchiveModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ unarchiveModal: false })}
      >
        <DeletePopup
          onPress={UnarchiveUser}
          onCancel={() => setState({ unarchiveModal: false })}
        />
      </CustomModal>
    </div>
  );
}

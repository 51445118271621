import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./view_store.screen.scss";
import History from "components/history/history.component";
import View from "components/view/view.component";
import Button from "common_components/ui/button/button.ui";
import Assets from "imports/assets.import";
import { Models } from "utils/imports.utils";
import { useSetState, toastifyError } from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";
import CustomModal from "common_components/ui/modal/modal.component";
import DeletePopup from "components/delete_popup/delete_popup.component";
import RateCards from "components/rate_cards/rate_cards.component";
import { ADMIN_ROLES } from "common_components/hoc/admin.pov";

export default function ViewStore() {
  const navigate = useNavigate();
  const { clientId, id } = useParams();
  const [state, setState] = useSetState({
    data: {},
    organization: {},
  });
  useEffect(() => {
    GetStore();
    if (ADMIN_ROLES.includes(localStorage.getItem("role") + ""))
      GetOrganization();
  }, []);

  const GetStore = async () => {
    try {
      const response: any = await Models.store.getStore({
        id: id,
      });
      console.log(response.data);
      setState({ data: response.data[0] });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const GetOrganization = async () => {
    try {
      const response: any = await Models.organization.getOrganization({
        organization_id: clientId,
      });
      setState({ organization: response.data });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const DeleteStore = async () => {
    try {
      await Models.store.deleteStore({
        store_id: state.data._id,
      });
      setState({ id: "", deleteModal: false });
      navigate(`/client/${clientId}/store`);
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const inputFields: IAddValues[] = [
    { label: "Store name", key: "storeName", type: "string" },
    { label: "Store ID", key: "storeId", type: "string" },
    {
      label: "Address",
      key: "fullAddress",
      type: "string",
      isNested: true,
    },
    { label: "Type", key: "type", type: "string" },
    { label: "State", key: "state", type: "string" },
    { label: "City", key: "city", type: "string" },
    {
      label: "Store incharge",
      key: "storeInCharge.name",
      type: "string",
      isNested: true,
    },
    {
      label: "Store incharge contact",
      key: "storeInCharge.number",
      type: "string",
      isNested: true,
    },
    {
      label: "Cluster lead",
      key: "ffCluster.fullName",
      type: "string",
      isNested: true,
    },
    {
      label: "Supervisor",
      key: "ffSupervisor.fullName",
      type: "string",
      isNested: true,
    },
    {
      label: "Control center",
      key: "controlCenterId.controlCenterName",
      type: "string",
      isNested: true,
    },
  ];

  const handleDelete = () => {
    setState({ deleteModal: true });
  };

  return (
    <div className="view_store_container">
      <div className="view_store_wrapper">
        <History
          name={state.data.name}
          path={`/client/${state?.data?.storeName}`}
        />
        <div className="view_store_body_container">
          <View
            actions={
              ADMIN_ROLES.includes(localStorage.getItem("role") + "")
                ? [
                    {
                      link: `/client/${clientId}/store/edit/${state.data._id}`,
                      icon: "edit",
                    },
                    { link: "/", icon: "delete", onClick: handleDelete },
                  ]
                : []
            }
            data={state.data}
            values={inputFields}
            head={<ViewHeader />}
            hasFiles
          />
          {state.data._id &&
          ADMIN_ROLES.includes(localStorage.getItem("role") + "") ? (
            <RateCards storeId={state.data._id} viewMode />
          ) : null}
        </div>
      </div>
      <CustomModal
        center
        open={state.deleteModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deleteModal: false })}
      >
        <DeletePopup
          onPress={DeleteStore}
          onCancel={() => setState({ deleteModal: false })}
        />
      </CustomModal>
    </div>
  );

  function ViewHeader() {
    return (
      <div className="view_head_left_container">
        <div className="view_head_title_container">
          <div className="view_head_title_wrapper">
            <div className="view_head_title">{state.data.storeName}</div>
            {/* <div className="view_head_sub_title h5">{`Enterprise - ${
              state?.data?.id || ''
            }`}</div> */}
          </div>
        </div>
      </div>
    );
  }
}

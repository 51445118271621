import React, { useEffect } from "react";
import History from "components/history/history.component";
import Add from "components/add/add.component";
import Button from "common_components/ui/button/button.ui";
import { useNavigate } from "react-router-dom";
import { useSetState, toastifyError, toastify } from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import { IAddValues } from "utils/interface.utils";
import {
  AADHAAR_BUCKET_URL,
  FACE_BUCKET_URL,
  PAN_BUCKET_URL,
} from "utils/constant.utils";
import encrypt from "utils/encrypt";
import { useSelector } from "react-redux";

export default function EditProfile() {
  const navigate = useNavigate();
  const user = useSelector((s: any) => s.auth?.user);
  const selectOption = [{ label: "Test", value: "test" }];
  const isEditable = true;
  const [state, setState] = useSetState({
    data: [],
    loading: false,
    changePwdLoading: false,
    selectOptions: selectOption,
    buttonDisabled: false,
  });
  const initialValue = {
    fullName: "",
    role: "",
    gender: "",
    dob: "",
    bloodGroup: "",
    mobile: "",
    wapNumber: "",
    secondaryMobileNo: "",
    fatherName: "",
    languages: "",
    email: "",
    aadhaarDocument: "",
    panDocument: "",
    userImage: "",
    controlCenterId: "",
    address: {
      fullAddress: "",
      city: "",
      state: "",
      pinCode: "",
      landMark: "",
      buildingName: "",
    },
  };
  const initialValueChangePassword = {
    password: "",
    confirm_password: "",
  };
  const inputFields: IAddValues[] = [
    { label: "Full name", key: "fullName", type: "string" },
    {
      label: "Role",
      key: isEditable ? "role[0]" : "role",
      type: "select",
      hide: isEditable ? true : false,
      options: [
        {
          label: "SA",
          value: "SA",
        },
        {
          label: "ADMIN",
          value: "ADMIN",
        },
      ],
    },
    {
      label: "Gender",
      key: "gender",
      type: "select",
      options: [
        {
          label: "Male",
          value: "Male",
        },
        {
          label: "Female",
          value: "Female",
        },
      ],
    },
    { label: "Dob", key: "dob", type: "birthdate" },
    {
      label: "Blood Group",
      key: "bloodGroup",
      type: "select",
      options: [
        {
          label: "A+",
          value: "A+",
        },
        {
          label: "A-",
          value: "A-",
        },
        {
          label: "B+",
          value: "B+",
        },
        {
          label: "B-",
          value: "B-",
        },
        {
          label: "O+",
          value: "O+",
        },
        {
          label: "O-",
          value: "O-",
        },
        {
          label: "AB+",
          value: "AB+",
        },
        {
          label: "AB-",
          value: "AB-",
        },
      ],
    },
    { label: "Mobile", key: "mobile", type: "string" },
    { label: "WhatsApp number", key: "wapNumber", type: "string" },
    {
      label: "Secondary mobile no",
      key: "secondaryMobileNo",
      type: "string",
      optional: true,
    },
    { label: "Father name", key: "fatherName", type: "string" },
    { label: "Languages (Comma separated)", key: "languages", type: "string" },
    // { label: "Languages", key: "languages", type: "multiselect" ,
    //   items : [
    //     { label: "Kannada", value: "Kannada" },
    //     { label: "Tamil", value: "Tamil" },
    //     { label: "Telegu", value: "Telegu" },
    //     { label: "English", value: "English" },
    //     { label: "Hindi", value: "Hindi" },
    //   ]
    // },
    {
      label: "Email",
      key: "email",
      type: "string",
      hide: isEditable ? true : false,
    },
    {
      label: "Aadhaar document",
      key: "aadhaarDocument",
      type: "file",
      file_bucket_url: AADHAAR_BUCKET_URL,
      secondaryKey: "private",
      hide: isEditable ? true : false,
    },
    {
      label: "Pan document",
      key: "panDocument",
      type: "file",
      file_bucket_url: PAN_BUCKET_URL,
      secondaryKey: "private",
      hide: isEditable ? true : false,
    },
    {
      label: "Profile image",
      key: "userImage",
      type: "file",
      file_bucket_url: FACE_BUCKET_URL,
    },
    {
      label: "Control Center ",
      key: "controlCenterId",
      type: "center",
      hide: true,
    },
    {
      label: "Full Address",
      key: isEditable ? "address[0].fullAddress" : "address.fullAddress",
      type: "string",
      isNested: true,
    },
    {
      label: "State",
      key: isEditable ? "address[0].state" : "address.state",
      type: "state",
      isNested: true,
    },
    {
      label: "City",
      key: isEditable ? "address[0].city" : "address.city",
      type: "city",
      isNested: true,
    },
    {
      label: "Pincode",
      key: isEditable ? "address[0].pinCode" : "address.pinCode",
      type: "string",
      isNested: true,
    },
    {
      label: "Landmark",
      key: isEditable ? "address[0].landMark" : "address.landMark",
      type: "string",
      isNested: true,
    },
    {
      label: "Building Name",
      key: isEditable ? "address[0].buildingName" : "address.buildingName",
      type: "string",
      isNested: true,
    },
  ];
  const changePassword: IAddValues[] = [
    { label: "Old Password", key: "oldPassword", type: "password" },
    {
      label: "New Password",
      key: "newPassword",
      type: "password",
    },
  ];
  useEffect(() => {
    GetUser();
  }, []);

  const GetUser = async () => {
    try {
      const response: any = await Models.user.getUser({
        id: user._id,
      });
      console.log(response.data);
      setState({ data: response.data[0] });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  // const handleEdit = async (values) => {
  //   try {
  //     const data: any = createPayloadUpdate(removeEmptyValues(values), [
  //       "role",
  //       "isApprovedByAdmin",
  //       "driverApproverStatus",
  //       "driverRegistrationType",
  //       "empId",
  //       "address",
  //       "createdAt",
  //       "updatedAt",
  //       "userImage",
  //       "forgetTokenVerificationStatus",
  //       "forgotToken",
  //       "forgotTokenCreationTime",
  //     ]);
  //     data.face = values.userImage;
  //     const formdata = jsonToFormData(data);
  //     await Models.user.editUser(formdata);
  //     navigate(`/profile`);
  //   } catch (err) {
  //     setState({ buttonDisabled: false });
  //     console.log(err);
  //     toastifyError(err);
  //   }
  // };
  const handleEdit = async (values) => {
    // console.log(values);
    try {
      setState({ loading: true });
      const final_data = {
        _id: user?._id,
        fullName: values?.fullName,
        role: Array.isArray(values?.role) ? values?.role[0] : null,
        gender: values?.gender,
        dob: values?.dob,
        bloodGroup: values?.bloodGroup,
        mobile: values?.mobile,
        wapNumber: values?.wapNumber,
        secondaryMobileNo: values?.secondaryMobileNo,
        fatherName: values?.fatherName,
        languages: values?.languages,
        email: values?.email,
        aadhaarDocument: values?.aadhaarDocument,
        panDocument: values?.panDocument,
        userImage: values?.userImage,
        controlCenterId: values?.controlCenterId,
        address: Array.isArray(values?.address)
          ? {
              fullAddress: values?.address[0]?.fullAddress,
              city: values?.address[0]?.city,
              state: values?.address[0]?.state,
              pinCode: values?.address[0]?.pinCode,
              landMark: values?.address[0]?.landMark,
              buildingName: values?.address[0]?.buildingName,
            }
          : null,
      };
      await Models.user.editUser(final_data);
      toastify("User Data updated");
      // navigate(`/user`);
    } catch (err) {
      console.log(err);
      toastifyError(err);
    } finally {
      setState({ loading: false });
    }
  };

  const resetPassword = async (values: any) => {
    if (values.password !== values.confirm_password) {
      toastifyError("Confirm password must match to the password");
    } else {
      try {
        const body = {
          email: localStorage.getItem("email"),
          newPassword: values.newPassword,
          oldPassword: values.oldPassword,
        };
        const jsonString = JSON.stringify(body);
        const data = await encrypt(jsonString);
        let res: any;
        res = await Models.auth.changePassword({ data });
        if (res.status === 1) toastify("Password changes successfully!!!");
        navigate("/");
      } catch (err: any) {
        toastifyError(err);
      }
    }
  };
  return (
    <div className="add_store_container">
      <div className="add_store_wrapper">
        <History name={state?.data?.name} path={`/Profile/edit Details`} />
        <div className="add_store_body_container">
          <Add
            title={"Edit Profile"}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputFields}
            initialValues={initialValue}
            validationSchema="user"
            onSubmit={handleEdit}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
      </div>
      <div className="add_store_wrapper">
        <History name={state?.data?.name} path={`/Profile/change password`} />
        <div className="add_store_body_container">
          <Add
            title={"Edit Profile"}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={changePassword}
            initialValues={initialValueChangePassword}
            validationSchema="user"
            onSubmit={resetPassword}
            buttons={
              <AddButtonChangePassword buttonDisabled={state.buttonDisabled} />
            }
            hasFiles
          />
        </div>
      </div>
    </div>
  );

  function AddButton(props: any) {
    return (
      <div className="view_button_container">
        <div className="view_button_wrapper">
          <div className="view_button">
            <Button
              buttonDisabled={state.loading}
              loading={state.loading}
              loadingText={"Updating your profile please wait ..."}
              value={"Update"}
            />
          </div>
        </div>
      </div>
    );
  }
  function AddButtonChangePassword(props: any) {
    return (
      <div className="view_button_container">
        <div className="view_button_wrapper">
          <div className="view_button">
            <Button
              buttonDisabled={state.changePwdLoading}
              loading={state.changePwdLoading}
              loadingText={"Updating your password please wait ..."}
              value={"Change Password"}
            />
          </div>
        </div>
      </div>
    );
  }
}

import React, { useEffect } from "react";
import History from "components/history/history.component";
import Add from "components/add/add.component";
import Button from "common_components/ui/button/button.ui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSetState, toastifyError, toastify } from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import { IAddValues } from "utils/interface.utils";
import {
  AADHAAR_BUCKET_URL,
  FACE_BUCKET_URL,
  PAN_BUCKET_URL,
} from "utils/constant.utils";

export default function EditUser() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const { id } = useParams();
  const params = useParams();
  const pathArr = pathname.split("/");
  const isEditable = pathArr.includes("edit");
  const selectOption = [{ label: "Test", value: "test" }];

  const [state, setState] = useSetState({
    data: [],
    loading: false,
    selectOptions: selectOption,
    buttonDisabled: false,
  });
  const initialValue = {
    fullName: "",
    role: "",
    gender: "",
    dob: "",
    bloodGroup: "",
    mobile: "",
    wapNumber: "",
    secondaryMobileNo: "",
    fatherName: "",
    languages: "",
    email: "",
    aadhaarDocument: "",
    panDocument: "",
    userImage: "",
    controlCenterId: "",
    address: {
      fullAddress: "",
      city: "",
      state: "",
      pinCode: "",
      landMark: "",
      buildingName: "",
    },
  };
  const inputFields: IAddValues[] = [
    { label: "Full name", key: "fullName", type: "string" },
    {
      label: "Role",
      key: isEditable ? "role[0]" : "role",
      type: "select",
      options: [
        {
          label: "SA",
          value: "SA",
        },
        {
          label: "ADMIN",
          value: "ADMIN",
        },
      ],
    },
    {
      label: "Gender",
      key: "gender",
      type: "select",
      options: [
        {
          label: "Male",
          value: "Male",
        },
        {
          label: "Female",
          value: "Female",
        },
      ],
    },
    { label: "Dob", key: "dob", type: "birthdate" },
    {
      label: "Blood Group",
      key: "bloodGroup",
      type: "select",
      optional: true,
      options: [
        {
          label: "A+",
          value: "A+",
        },
        {
          label: "A-",
          value: "A-",
        },
        {
          label: "B+",
          value: "B+",
        },
        {
          label: "B-",
          value: "B-",
        },
        {
          label: "O+",
          value: "O+",
        },
        {
          label: "O-",
          value: "O-",
        },
        {
          label: "AB+",
          value: "AB+",
        },
        {
          label: "AB-",
          value: "AB-",
        },
      ],
    },
    {
      label: "Mobile",
      key: "mobile",
      type: "string",
      hide: isEditable ? true : false,
    },
    {
      label: "WhatsApp number",
      key: "wapNumber",
      type: "string",
      optional: true,
    },
    {
      label: "Secondary mobile no",
      key: "secondaryMobileNo",
      type: "string",
      optional: true,
    },
    { label: "Father name", key: "fatherName", type: "string" },
    {
      label: "Languages (Comma separated)",
      key: "languages",
      type: "string",
      optional: true,
    },
    {
      label: "Email",
      key: "email",
      type: "string",
      hide: isEditable ? true : false,
    },
    {
      label: "Aadhaar document",
      key: "aadhaarDocument",
      type: "file",
      file_bucket_url: AADHAAR_BUCKET_URL,
      secondaryKey: "private",
    },
    {
      label: "Pan document",
      key: "panDocument",
      type: "file",
      file_bucket_url: PAN_BUCKET_URL,
      secondaryKey: "private",
    },
    {
      label: "Profile image",
      key: "userImage",
      type: "file",
      file_bucket_url: FACE_BUCKET_URL,
    },
    { label: "Control center ", key: "controlCenterId", type: "center" },
    {
      label: "Full Address",
      key: isEditable ? "address[0].fullAddress" : "address.fullAddress",
      type: "string",
      isNested: true,
    },
    {
      label: "State",
      key: isEditable ? "address[0].state" : "address.state",
      type: "state",
      isNested: true,
    },
    {
      label: "City",
      key: isEditable ? "address[0].city" : "address.city",
      type: "city",
      isNested: true,
    },
    {
      label: "Pincode",
      key: isEditable ? "address[0].pinCode" : "address.pinCode",
      type: "string",
      isNested: true,
    },
    {
      label: "Landmark",
      key: isEditable ? "address[0].landMark" : "address.landMark",
      type: "string",
      isNested: true,
      optional: true,
    },
    {
      label: "Building Name",
      key: isEditable ? "address[0].buildingName" : "address.buildingName",
      type: "string",
      isNested: true,
      optional: true,
    },
  ];
  useEffect(() => {
    if (isEditable) GetUser();
  }, []);

  const GetUser = async () => {
    try {
      const response: any = await Models.user.getUser({
        id: params?.id,
      });
      console.log(response.data);
      setState({ data: response.data[0] });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const handleCreate = async (values) => {
    console.log(values);

    try {
      setState({ loading: true });
      const final_data = {
        fullName: values?.fullName,
        role: values?.role,
        gender: values?.gender,
        dob: values?.dob,
        bloodGroup: values?.bloodGroup,
        mobile: values?.mobile,
        wapNumber: values?.wapNumber,
        secondaryMobileNo: values?.secondaryMobileNo,
        fatherName: values?.fatherName,
        languages: values?.languages,
        // "languages":  JSON.stringify(values?.languages),
        email: values?.email,
        aadhaarDocument: values?.aadhaarDocument,
        panDocument: values?.panDocument,
        userImage: values?.userImage,
        controlCenterId: values?.controlCenterId,
        address: values?.address,
      };
      // console.log("FINAL DATA", final_data);
      await Models.user.editUser(final_data);
      toastify("User created");
      navigate(`/user`);
    } catch (err) {
      console.log(err);
      toastifyError(err);
    } finally {
      setState({ loading: false });
    }
  };
  const handleEdit = async (values) => {
    // console.log(values);
    try {
      setState({ loading: true });
      const final_data = {
        _id: params.id,
        fullName: values?.fullName,
        role: Array.isArray(values?.role) ? values?.role[0] : null,
        gender: values?.gender,
        dob: values?.dob,
        bloodGroup: values?.bloodGroup,
        mobile: values?.mobile,
        wapNumber: values?.wapNumber,
        secondaryMobileNo: values?.secondaryMobileNo,
        fatherName: values?.fatherName,
        languages: values?.languages,
        email: values?.email,
        aadhaarDocument: values?.aadhaarDocument,
        panDocument: values?.panDocument,
        userImage: values?.userImage,
        controlCenterId: values?.controlCenterId,
        address: Array.isArray(values?.address)
          ? {
              fullAddress: values?.address[0]?.fullAddress,
              city: values?.address[0]?.city,
              state: values?.address[0]?.state,
              pinCode: values?.address[0]?.pinCode,
              landMark: values?.address[0]?.landMark,
              buildingName: values?.address[0]?.buildingName,
            }
          : null,
      };
      await Models.user.editUser(final_data);
      toastify("User Data updated");
      // navigate(`/user`);
    } catch (err) {
      console.log(err);
      toastifyError(err);
    } finally {
      setState({ loading: false });
    }
  };

  return (
    <div className="add_store_container">
      <div className="add_store_wrapper">
        <History
          name={state?.data?.name}
          path={isEditable ? `/User/edit` : "User/add"}
        />
        <div className="add_store_body_container">
          <Add
            title={"Edit User"}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputFields}
            initialValues={initialValue}
            validationSchema="user"
            onSubmit={isEditable ? handleEdit : handleCreate}
            buttons={<AddButton />}
            hasFiles
          />
        </div>
      </div>
    </div>
  );

  function AddButton(props: any) {
    return (
      <div className="view_button_container">
        <div className="view_button_wrapper">
          <div className="view_button">
            <Button
              buttonDisabled={state.loading}
              loading={state.loading}
              loadingText={
                isEditable
                  ? "Updating your details please wait ..."
                  : "Creating user please wait..."
              }
              value={isEditable ? "Update" : "Add user"}
            />
          </div>
        </div>
      </div>
    );
  }
}

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./view_driver.screen.scss";
import History from "components/history/history.component";
import View from "components/view/view.component";
import Assets from "imports/assets.import";
import { Models } from "utils/imports.utils";
import { toastify, toastifyError, useSetState } from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";
import CustomModal from "common_components/ui/modal/modal.component";
import DeletePopup from "components/delete_popup/delete_popup.component";

export default function ViewDriver() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, setState] = useSetState({
    data: {},
    deleteModal: false,
    vehicleAllocated: [],
    deallocateModal: false,
  });
  useEffect(() => {
    GetDriver();
  }, []);
  useEffect(() => {
    getAllocatedVehicle();
  }, [state.dId]);

  const GetDriver = async () => {
    try {
      const response: any = await Models.driver.getDriver({
        id: id,
      });
      setState({ data: response.data[0], dId: response.data[0]._id });
    } catch (err) {
      console.log(err);
    }
  };
  const getAllocatedVehicle = async () => {
    try {
      let res: any;
      const body: any = {
        dId: state.dId,
      };
      res = await Models.vehicle.getAllocatedDriver(body);
      setState({ vehicleAllocated: res.data[0] });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };
  const inputFields: IAddValues[] = [
    { label: "Gender", key: "userId.gender", type: "string", isNested: true },
    { label: "D.O.B", key: "userId.dob", type: "date", isNested: true },
    {
      label: "Blood Group",
      key: "userId.bloodGroup",
      type: "string",
      isNested: true,
    },
    { label: "Mobile", key: "userId.mobile", type: "string", isNested: true },
    {
      label: "Whatsapp no.",
      key: "userId.wapNumber",
      type: "string",
      isNested: true,
    },
    {
      label: "Secondary no.",
      key: "userId.secondaryMobileNo",
      type: "string",
      isNested: true,
    },
    {
      label: "Father's Name",
      key: "userId.fatherName",
      type: "string",
      isNested: true,
    },
    {
      label: "State",
      key: "userId.address",
      secondaryKey: "state",
      type: "address",
      isNested: true,
    },
    {
      label: "City",
      key: "userId.address",
      secondaryKey: "city",
      type: "address",
      isNested: true,
    },
    {
      label: "Full address",
      key: "userId.address",
      secondaryKey: "fullAddress",
      type: "address",
      isNested: true,
    },
    {
      label: "Pincode",
      key: "userId.address",
      secondaryKey: "pinCode",
      type: "address",
      isNested: true,
    },
    {
      label: "Languages",
      key: "userId.languages",
      type: "array",
      isNested: true,
    },
    // { label: "Aadhaar card no", key: "dAadhaarCardNumber", type: "string" },
    // { label: "Lisence no", key: "dDlNumber", type: "string" },
    // { label: "Pancard no", key: "dPanNumber", type: "string" },
    {
      label: "Aadhaar card front",
      key: "aadhaarCardFront",
      type: "passbook",
    },
    {
      label: "Aadhar card back",
      key: "aadhaarCardBack",
      type: "passbook",
    },
    {
      label: "Pancard front",
      key: "panCardFront",
      type: "passbook",
    },
    {
      label: "Pancard back",
      key: "panCardBack",
      type: "passbook",
    },
    {
      label: "Driving liscence front",
      key: "dlFront",
      type: "passbook",
    },
    {
      label: "Driving liscence back",
      key: "dlBack",
      type: "passbook",
    },
    {
      label: "Account holder's name",
      key: "bankDetails.acHolderName",
      isNested: true,
      type: "string",
    },
    {
      label: "Account no.",
      key: "bankDetails.acNumber",
      isNested: true,
      type: "string",
    },
    {
      label: "IFSC Code ",
      key: "bankDetails.ifscCode",
      isNested: true,
      type: "string",
    },
    {
      label: "Passbook front",
      key: "bankDetails.passbookFront",
      isNested: true,
      type: "passbook",
    },
    {
      label: "Deposited amount",
      key: "driverDeposit",
      isNested: true,
      type: "string",
    },
    {
      label: "Deposited Remarks",
      key: "driverDepositRemarks",
      isNested: true,
      type: "string",
    },
    {
      label: "Contact Name (Emergency)",
      key: "emergencyDetails.name",
      isNested: true,
      type: "string",
    },
    {
      label: "Mobile (Emergency)",
      key: "emergencyDetails.mobile",
      isNested: true,
      type: "string",
    },
    {
      label: "City (Emergency)",
      key: "emergencyDetails.city",
      isNested: true,
      type: "string",
    },
    {
      label: "Address (Emergency)",
      key: "emergencyDetails.address",
      isNested: true,
      type: "string",
    },
    {
      label: "Pincode (Emergency)",
      key: "emergencyDetails.pinCode",
      isNested: true,
      type: "string",
    },
    {
      label: "Relation (Emergency)",
      key: "emergencyDetails.relation",
      isNested: true,
      type: "string",
    },
  ];
  const handleDelete = (data) => {
    setState({ id: data._id, deleteModal: true });
  };
  const DeleteDriver = async () => {
    try {
      await Models.driver.deleteDriver({
        ids: [id],
        target: "delete",
        checkCreatedBy: false,
        hardDelParam: false,
        reverterParam: false,
      });
      setState({ id: "", deleteModal: false });
      navigate("/driver");
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const handleViewAllottedVehicle = () => {
    navigate(`/vehicle/view/${state?.vehicleAllocated?.vehicleId?._id}`);
  };
  const handleDeAllocation = async () => {
    try {
      const res: any = await Models.driver.deAllocation(
        state?.vehicleAllocated?._id
      );
      setState({ deleteModal: false });
      toastify("Driver de-allocated successfully!");
      await GetDriver();
      await getAllocatedVehicle();
      setState({ deallocateModal: false });
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  return (
    <div className="view_driver_container">
      <div className="view_driver_wrapper">
        <History name={state?.data?.userId?.fullName} path="view" />
        <div className="view_driver_body_container">
          <View
            actions={[
              { link: `/driver/edit/${id}`, icon: "edit" },
              { link: "/", icon: "delete", onClick: handleDelete },
            ]}
            data={state.data}
            values={inputFields}
            head={<ViewHeader />}
            // buttons={<ViewButton />}
            hasFiles
          />
        </div>
      </div>
      <CustomModal
        center
        open={state.deleteModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deleteModal: false })}
      >
        <DeletePopup
          onPress={DeleteDriver}
          onCancel={() => setState({ deleteModal: false })}
        />
      </CustomModal>
    </div>
  );
  function ViewHeader() {
    return (
      <div className="view_head_left_container">
        <div className="view_head_image_conatiner">
          <img
            className="view_head_image"
            src={state?.data?.userId?.userImage || Assets.testPic}
            alt="head_image"
          />
        </div>
        <div className="view_head_title_container">
          <div className="view_head_title_wrapper">
            <div className="view_head_title">
              {state?.data?.userId?.fullName}
            </div>
            {state.vehicleAllocated && (
              <div className="allocated_to">
                Allocated to - {state.vehicleAllocated?.vehicleId?.vhNumber}{" "}
                <img
                  src={Assets["view"]}
                  onClick={() => handleViewAllottedVehicle()}
                />
                <img
                  src={Assets["delete"]}
                  onClick={() => setState({ deallocateModal: true })}
                />
              </div>
            )}
          </div>
        </div>
        <CustomModal
          center
          open={state.deallocateModal}
          classNames={{ modalContainer: "delete_modal_container" }}
          onClose={() => setState({ deallocateModal: false })}
        >
          <div className="reject_modal_container">
            <div className="reject_heading">
              Are you sure you want to deallocate vehicle?
            </div>
            <div className="reject_modal_button">
              <div
                onClick={() => {
                  setState({ deallocateModal: false });
                }}
                className="reject_cancel_button"
              >
                No
              </div>
              <div
                onClick={() => handleDeAllocation()}
                className="reject_button"
              >
                Yes
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    );
  }
}

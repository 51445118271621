import React, { FC, useEffect, useRef, useState } from 'react'
import CustomModal from '../modal/modal.component';
//@ts-ignore
import GMapPicker from 'react-google-map-picker'
import "./google_maps_picker.ui.scss"
import { MapTypeId } from 'utils/interface.utils';
import Button from '../button/button.ui';
import GoogleAutocompletePlaces from '../google_autocomplete_place/google_autocomplete_place.ui';
export interface MapPickerProps {
  label?: string;
  value?: string;
  onChange: (location: {
    lng: number, lat: number,
    formatted_address?: string,
  }) => void
  style?: any;
  placeholder?: string
}


const MapPicker: FC<MapPickerProps> = ({
  label = "Label",
  onChange,
  style = { height: "500px", width: "500px" },
  placeholder = "Choose your location",
  value,
  ...rest
}) => {
  const [mapOpen, setMapOpen] = useState(false);
  const [location, setLocation] = useState<{
    lat: number;
    lng: number;
    formatted_address?: string;
  }>({ lat: 13.0739183, lng: 80.1907184  });
  const [zoom, setZoom] = useState(16);


  async function getLocation() {
    setZoom(15)
  }

  function handleChangeLocation(lat: number, lng: number) {
    setLocation((prev)=>({...prev, lat: lat, lng: lng }));
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }
  useEffect(() => {
    getLocation();
  }, [])
  return (
    <React.Fragment>
      <div style={{ cursor: 'pointer' }} onClick={() => setMapOpen(true)} className="input_container">
        <div className="label_container caption2">{label}</div>
        <div className="field_wrap">
          <div className="map-pick-box">
            {
              !location.formatted_address ? 
              (
                value ? 
                  value
                : "Door no, Street name, Area name"
              )
              :
              location.formatted_address
            }
          </div>
        </div>
      </div>
      <CustomModal open={mapOpen} onClose={() => setMapOpen(false)}>
        <GoogleAutocompletePlaces
          value={location}
          onChange={(loc) => setLocation(loc)}

        />
        <div className="google_picker_container">
          <div style={{ width: style.width ?? 400, height: style.height ?? 400, margin: "20px 0", borderRadius: 20, overflow: 'hidden' }} className="map_picker_container">
            {
              location.formatted_address ?
                <GMapPicker
                  defaultLocation={location}
                  zoom={zoom}
                  mapTypeId={MapTypeId.Roadmap}
                  onChangeLocation={handleChangeLocation}
                  onChangeZoom={handleChangeZoom}
                  className="google_maps"
                  apiKey={"AIzaSyAaPjnlGUIBa9D65PqESe560a_DJ-gY0Oc"}
                />
                : null
            }
          </div>
          <div onClick={() => {
            setMapOpen(false);
            onChange(location)
          }} className="button_container">
            <Button value="Submit" />
          </div>
        </div>
      </CustomModal>
    </React.Fragment>
  )
}

export default MapPicker
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./view_center.screen.scss";
import History from "components/history/history.component";
import View from "components/view/view.component";
import Assets from "imports/assets.import";
import { Models } from "utils/imports.utils";
import { useSetState, toastifyError } from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";

export default function ViewCenter() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useSetState({
    data: {},
    deleteModal: false,
    members: {},
  });
  useEffect(() => {
    GetCenter();
    GetCenterMembers();
  }, []);

  const GetCenter = async () => {
    try {
      const response: any = await Models.center.getCenter({
        id: id,
      });
      setState({ data: response.data[0] });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const GetCenterMembers = async () => {
    try {
      const response: any = await Models.center.getCenterMembers({
        id: id,
      });
      setState({ members: response });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const inputFields: IAddValues[] = [
    { label: "Name", key: "controlCenterName", type: "string" },
    { label: "Phone", key: "phone", type: "string" },
    { label: "State", key: "state", type: "string" },
    { label: "City", key: "city", type: "string" },
    { label: "Full address", key: "fullAddress", type: "string" },
    { label: "Pincode", key: "pinCode", type: "string" },

    {
      label: "Center incharge",
      key: "centerInCharge.email",
      type: "string",
      isNested: true,
    },
    // {
    //   label: "Center members",
    //   key: "centerMembers",
    //   secondaryKey: "email",
    //   type: "address",
    //   isNested: true,
    // },
  ];

  return (
    <div className="view_center_container">
      <div className="view_center_wrapper">
        <History name={state?.data?.controlCenterName} path="view" />
        <div className="view_center_body_container">
          <View
            actions={[
              {
                link: `/center/edit/${id}`,
                icon: "edit",
              },
            ]}
            data={state.data}
            values={inputFields}
            head={<ViewHeader />}
            // buttons={<ViewButton />}
            hasFiles
          />
          {state?.members?.data?.length > 0 && (
            <div className="data-view">
              <h4>Center Members :</h4>
              {state?.members?.data?.map((item, index) => (
                <div key={index}>
                  <div className="role">
                    <strong>Role:</strong> {item._id[0]}
                  </div>
                  <div className="documents">
                    <ul>
                      {item.documents.map((document, docIndex) => (
                        <li key={docIndex}>
                          <div>
                            <img src={document.userImage || Assets["user"]} />
                            {document.fullName}
                          </div>
                          <div>{document.email}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  function ViewHeader() {
    return (
      <div className="view_head_left_container">
        <div className="view_head_title_container">
          <div className="view_head_title_wrapper">
            <div className="view_head_title">
              {state?.data?.controlCenterName}
            </div>
            <div className="view_head_sub_title h5">{`Center ID - ${
              state?.data?.centerId || ""
            }`}</div>
          </div>
        </div>
      </div>
    );
  }
}

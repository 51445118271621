import ActionButton from "common_components/ui/action_button/action_button.ui";
import {
  checkIfValidImage,
  filenameFromURL,
  getNestedObjectValue,
  toastifyError,
  useSetState,
} from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";
import NavButton from "common_components/ui/nav_button/nav_button.ui";
import CustomModal from "common_components/ui/modal/modal.component";
import "./view.component.scss";
import moment from "moment";
import Models from "imports/models.import";
import { useEffect, useState } from "react";

interface viewProps {
  data: any;
  values: IAddValues[];
  actions?: { link?: string; label?: string; icon: string; onClick?: any }[];
  hasFiles?: boolean;
  head?: any;
  buttons?: any;
}

export default function View(props: viewProps) {
  const [state, setState] = useSetState({
    view: false,
    viewURL: "",
    passbookPublicURL: "",
  });
  const [publicUrl, setPublicUrl] = useState([]);
  const { data, values, actions = [], hasFiles, head, buttons } = props;
  const handleView = () => {
    setState({ view: !state.view, viewURL: "" });
  };
  useEffect(() => {
    // Check if the data[key] exists and is not already a public URL
    values.forEach(({ key, type }) => {
      if (
        type === "passbook" &&
        (data[key] || getNestedObjectValue(data, key)) &&
        !isPublicURL(data[key]) &&
        !state[`${key}PublicURL`]
      ) {
        convertToPublicUrl(key, getNestedObjectValue(data, key));
      }
    });
  }, [data]);
  const checkFileType = (data: any, key: any) => {
    const type = data[key]?.split(".").pop();
    if (type === "pdf") {
      return "https://bb-adsquare.s3.ap-south-1.amazonaws.com/1677849567430fN9iiE45fK.png";
    } else return data[key];
  };
  const convertToPublicUrl = async (key, url) => {
    try {
      const body: any = {
        bucketName: process.env.REACT_APP_SIGNED_BUCKET_KEY,
        file: url,
        expiresIn: 3000,
      };
      const response: any = await Models.signedUrl.getSignedUrl(body);

      // Check if the response has a valid link
      if (response?.data?.link) {
        setPublicUrl((prevState) => ({
          ...prevState,
          [`${key}PublicURL`]: response?.data?.link,
        }));
      } else {
        console.error("Invalid public URL in the response:", response);
        // Handle the error or log it as needed
      }
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const isPublicURL = (url) => {
    return url?.startsWith("https://");
  };

  return (
    <div className="view_container">
      <div className="view_wrapper">
        <div className="view_head_container">
          <div className="view_head_wrapper">
            {head}
            <div className="view_head_right_container">
              <div className="view_head_nav_icon_wrapper">
                {actions.length > 0 &&
                  actions.map(({ link, icon, onClick, label }) =>
                    onClick ? (
                      <div className="view_head_nav_icon">
                        <ActionButton
                          icon={icon}
                          onClick={onClick}
                          label={label}
                        />
                      </div>
                    ) : (
                      <div className="view_head_nav_icon">
                        <NavButton link={link || ""} icon={icon} />
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="view_field_body_container">
          <div className="view_field_body_wrapper">
            {values.map(({ label, key, type, isNested, secondaryKey }) => {
              // !(getNestedObjectValue(data, key) || data[key]) ? null
              // :
              if (!data)
                return (
                  <div className="view_field_container">
                    <div className="view_field_label caption2">{label}</div>
                    <div className="view_field menu">Data not Present</div>
                  </div>
                );
              return type === "string" ? (
                <div className="view_field_container">
                  <div className="view_field_label caption2">{label}</div>
                  <div className="view_field menu">
                    {isNested ? getNestedObjectValue(data, key) : data[key]}
                  </div>
                </div>
              ) : type === "number" ? (
                <div className="view_field_container">
                  <div className="view_field_label caption2">{label}</div>
                  <div className="view_field menu">
                    {isNested ? getNestedObjectValue(data, key) : data[key]}
                  </div>
                </div>
              ) : type === "textarea" ? (
                <div className="view_field_container">
                  <div className="view_field_label caption2">{label}</div>
                  <div className="view_field menu">
                    {isNested ? getNestedObjectValue(data, key) : data[key]}
                  </div>
                </div>
              ) : type === "array" ? (
                <div className="view_field_container">
                  <div className="view_field_label caption2">{label}</div>
                  <div className="view_field menu">
                    {isNested
                      ? (getNestedObjectValue(data, key) || "").replace(
                          /["\[\]]/g,
                          ""
                        )
                      : data[key]}
                  </div>
                </div>
              ) : type === "address" ? (
                <div className="view_field_container">
                  <div className="view_field_label caption2">{label}</div>
                  <div className="view_field menu">
                    {(getNestedObjectValue(data, key) || []).map(
                      (item, index) => (
                        <div key={index}>
                          {getNestedObjectValue(item, secondaryKey)}
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : type === "boolean" ? (
                <div className="view_field_container">
                  <div className="view_field_label caption2">{label}</div>
                  <div className="view_field menu">
                    {data[key] === true ? "Yes" : "No"}
                  </div>
                </div>
              ) : (
                type === "date" &&
                data[key] && (
                  <div className="view_field_container">
                    <div className="view_field_label caption2">{label}</div>
                    <div className="view_field menu">
                      {moment(data[key]).format("DD-MM-YYYY hh:mma")}
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
        {hasFiles && (
          <>
            <div className="view_field_body_container">
              <div className="view_field_body_wrapper">
                {values.map(({ label, key, type, isNested }) =>
                  !data ? (
                    <div className="view_field_container">
                      <div className="view_field_label caption2">{label}</div>
                      <div className="view_field menu">Data not Present</div>
                    </div>
                  ) : type === "file" &&
                    (data[key] || getNestedObjectValue(data, key)) ? (
                    <div className="view_field_container">
                      <div className="view_field_label caption2">
                        {label}
                        <a
                          href={
                            isNested
                              ? getNestedObjectValue(data, key)
                              : checkFileType(data, key)
                          }
                          download={filenameFromURL(state.viewURL)}
                        >
                          Download
                        </a>
                      </div>
                      <div
                        onClick={() =>
                          setState({
                            view: true,
                            viewURL: isNested
                              ? getNestedObjectValue(data, key)
                              : data[key],
                          })
                        }
                        className="view_field img"
                      >
                        <img
                          src={
                            isNested
                              ? getNestedObjectValue(data, key)
                              : checkFileType(data, key)
                          }
                          className="view_file"
                        />
                      </div>
                    </div>
                  ) : type === "passbook" &&
                    (data[key] || getNestedObjectValue(data, key)) ? (
                    <div className="view_field_container">
                      <div className="view_field_label caption2">
                        {label}
                        {/* <a
                          href={
                            isNested
                              ? getNestedObjectValue(data, key)
                              : checkFileType(data, key)
                          }
                          download={filenameFromURL(
                            publicUrl[`${key}PublicURL`]
                          )}
                        >
                          Download
                        </a> */}
                      </div>
                      {publicUrl[`${key}PublicURL`] && (
                        <div 
                        onClick={() =>
                          setState({
                            view: true,
                            viewURL: publicUrl[`${key}PublicURL`],
                          })
                        }
                        className="view_field img">
                          <img
                            src={publicUrl[`${key}PublicURL`]}
                            className="view_file"
                          />
                        </div>
                      )}
                    </div>
                  ) : null
                )}
              </div>
            </div>
          </>
        )}
        {buttons && buttons}
      </div>
      <CustomModal open={state.view} onClose={handleView}>
        {state.viewURL ? (
          <div className="view_file_container">
            {
            // checkIfValidImage(state.viewURL) ? (
              <img
                style={{height : 600 , width : 800 , objectFit : 'contain'}}
                src={state.viewURL}
                alt="upload image"
                className="view_file"
              />
            // ) : (
            //   <a href={state.viewURL} download={filenameFromURL(state.viewURL)}>
            //     Download
            //   </a>
            // )
            }
          </div>
        ) : (
          <div className="view_file_error_container">
            <div className="view_file_error">No file chosen</div>
          </div>
        )}
      </CustomModal>
    </div>
  );
}

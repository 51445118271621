import Main from 'common_components/hoc/main.hoc'
import React, { FC, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { ADMIN_ROLES } from './admin.pov'

const ProtectedRoutes: FC<any> = () => {
  const auth = useSelector((s:any)=>s.auth);
  if (!auth.loggedIn) {
    return <Navigate to="/" />
  }
  return (
    <Main>
      <Outlet />
    </Main>
  )
}

export default ProtectedRoutes
export const sampleConstant = "sampleConstant";
export const autoRefreshTime = 10000;
export const colorsArray = [
  "#7397C9",
  "#FB7D5B",
  "#FCC43E",
  "#CBCBD2",
  "#EEB992",
  "#491800",
];

export const STATUSES = {
  IDLE : 'IDLE',
  SUCCESS : 'SUCCESS',
  FAILED : "FAILED",
  PENDING : "PENDING"
}


//s3 buckets url
export const FACE_BUCKET_URL = "/s3/bucket/face/pub"
export const AADHAAR_BUCKET_URL = "/s3/bucket/aadhaar-docs/prv"
export const PAN_BUCKET_URL = "/s3/bucket/pan-docs/prv"
export const DL_BUCKET_URL = "/s3/bucket/dl-docs/prv"
export const PASSBOOK_BUCKET_URL = "/s3/bucket/passbook-docs/prv"
export const VEHICLE_IMAGE_UPLOAD_URL = "/s3/bucket/checkin-vehicle/pub"
export const DRIVER_SELFIE_UPLOAD_URL = "/s3/bucket/checkin-selfie/pub"
export const GST_UPLOAD_URL = "/s3/bucket/gst-doc/prv"
export const CANCELLED_CHEQUE_UPLOAD_URL = "/s3/bucket/canceled-cheque/prv"
export const AGREEMENT_DOC_UPLOAD_URL = "/s3/bucket/agreement-doc/prv"
export const RC_DOC_UPLOAD_URL = "/s3/bucket/rc-docs/prv"
import Assets from "imports/assets.import";
import React, { FC, useState } from "react";
import { toastifyError } from "utils/functions.utils";

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  fileKey: string;
  label: string;
  secondaryKey?: string;
  onChangeFile: (file: any, responseData: any) => void;
  file_bucket_url?: string;
  optional?: boolean;
}

const FileInput: FC<FileInputProps> = ({
  fileKey = "",
  label = "",
  secondaryKey = "",
  onChangeFile = (file: any, responseData: any) => {},
  file_bucket_url = "",
  optional = false,
  ...rest
}) => {
  const [tempFile, setTempFile] = useState<{
    url: string | null;
    name: string | null;
  }>({
    url: null,
    name: null,
  });

  const handleFileUpload = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      let response: any = [];

      if (secondaryKey === "private") {
        response = await fetch(
          `${process.env.REACT_APP_NODE}/api/v2${file_bucket_url}`,
          {
            method: "POST",
            body: formData,
          }
        );
      } else {
        response = await fetch(
          `${process.env.REACT_APP_NODE}/api/v2${file_bucket_url}`,
          {
            method: "POST",
            body: formData,
          }
        );
      }

      if (response.ok) {
        const responseData = await response.json();
        console.log("Upload successful:", responseData);
        setTempFile({
          url: URL.createObjectURL(file),
          name: file.name,
        });
        if (secondaryKey === "private") {
          onChangeFile(responseData.data.key, fileKey);
        } else {
          onChangeFile(responseData.data.location, fileKey);
        }
      } else {
        toastifyError("File upload failed: Image size too big");
      }
    } catch (error) {
      toastifyError("Error uploading file: Image size too big");
    }
  };

  return (
    <>
      <label style={{ width: "100%" }} className="img-title" htmlFor={fileKey}>
        <div className="file-label">
          {label}
          {!optional && (
            <span className="required" style={{ color: "red" }}>
              *
            </span>
          )}
        </div>
        <div className="file-input-container">
          <div className={`file-show ${tempFile.url ? "show" : ""}`}>
            <img src={tempFile.url ?? Assets.file_placeholder} alt="" />
          </div>
          <div className="choose-btn">Choose file</div>
          <div className="file-placeholder">
            {tempFile.name ?? "Only PDF, JPG, PNG file supported"}
          </div>
        </div>
      </label>
      <div className="img-box">
        <input
          type="file"
          style={{ display: "none" }}
          id={fileKey}
          accept="image/png, image/jpg, image/jpeg"
          onChange={(event) => {
            if (
              event.currentTarget.files &&
              event.currentTarget.files.length > 0
            ) {
              setTempFile({
                url: URL.createObjectURL(event?.currentTarget?.files[0]),
                name: event?.currentTarget?.files[0]?.name,
              });
              handleFileUpload(event?.currentTarget?.files[0]);
            }
          }}
          {...rest}
        />
      </div>
    </>
  );
};

export default FileInput;

import React, { useState } from "react";
import { customAlphabet } from "nanoid";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

export const useSetState = (initialState: any) => {
  const [state, setState] = useState(initialState);

  const newSetState = (newState: any) => {
    setState((prevState: any) => ({ ...prevState, ...newState }));
  };
  return [state, newSetState];
};

export const modelError = (error: any) => {
  console.log(JSON.stringify(error?.response));
  if (error.response?.data.message) {
    return error.response?.data.message;
  } else if (error.message) {
    return error.message;
  } else if (error.response) {
    return error.response;
  } else {
    return error;
  }
};

export const nanoid = () => {
  const alphabet =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const nanoId = customAlphabet(alphabet, 10);
  return nanoId();
};

export const Options = (callBack) => {
  const options = {
    onUploadProgress: (progressEvent) => {
      const { total, loaded } = progressEvent;
      const completePercentage: any = (loaded / total) * 100;
      callBack(parseInt(completePercentage));
    },
  };
  return options;
};

export const removeEmptyValues = (object = {}) => {
  const data = {};
  Object.keys(object).forEach((item) => {
    if (typeof object[item] === "boolean") {
      data[item] = object[item];
    } else if (object[item]) {
      data[item] = object[item];
    }
  });
  return data;
};

export const getNestedObjectValue = (nestedObj, path) => {
  if (nestedObj) {
    const pathArr = path.split(/\.|\[(\d+)\]/).filter(Boolean);
    return pathArr.reduce((obj, key) => {
      if (obj && obj.hasOwnProperty(key)) {
        return obj[key];
      } else {
        return undefined;
      }
    }, nestedObj);
  } else {
    return "DATA not Available";
  }
};

export const toastifyError = (text?: any) => {
  toast.error(text, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const toastify = (text?: any) => {
  toast(text, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const ErrorMessage = (error) => {
  toast.error(error);
};

export const upperCase = (text: string): string => {
  text = text.toUpperCase();
  text = text.replace(/_/g, " ");
  return text;
};

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const checkIfValidImage = (url) => {
  if (!url) {
    return false;
  }

  if (Array.isArray(url)) {
    if (url.length === 0) {
      return false;
    }
    url = url[0];
  }

  if (typeof url !== "string") {
    return false;
  }

  const urlSplit = url.split(".");
  if (urlSplit.length < 2) {
    return false;
  }

  const ext = urlSplit[urlSplit.length - 1].toLowerCase();

  if (ext === "jpg" || ext === "jpeg" || ext === "png") {
    return true;
  }
  return false;
};

export const filenameFromURL = async (url) => {
  if (!url) {
    return false;
  }

  try {
    let validUrl = url;
    while (Array.isArray(validUrl) && validUrl.length) {
      validUrl = validUrl[0];
    }

    if (validUrl === null) {
      return null;
    }

    const urlSplit = validUrl.split("/");
    const filename = urlSplit[urlSplit.length - 1];
    return filename;
  } catch (error) {
    return null;
  }
};

export const printDiv = (divName) => {
  let printContents = document.getElementById(divName)?.innerHTML;
  let originalContents = document.body.innerHTML;

  document.body.innerHTML = printContents || originalContents;

  window.print();

  document.body.innerHTML = originalContents;
};

export const timeConvert = (seconds: any) => {
  var days = Math.floor(seconds / (24 * 60 * 60));
  seconds -= days * (24 * 60 * 60);
  var hours = Math.floor(seconds / (60 * 60));
  seconds -= hours * (60 * 60);
  var minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  return (
    (0 < days ? days + "d " : "") +
    (0 < hours ? hours + "h " : "") +
    minutes +
    "m"
  );
};

export const timeConvertStatus = (seconds: any) => {
  var days = Math.floor(seconds / (24 * 60 * 60));
  seconds -= days * (24 * 60 * 60);
  var hours = Math.floor(seconds / (60 * 60));
  seconds -= hours * (60 * 60);
  var minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  return (
    (0 < days ? days + "day " : "") +
    (0 < hours ? hours + "hours " : "") +
    minutes +
    "mins"
  );
};

export const capitalizeFirstLetter = (string: string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "";
  }
};
export const jsonToFormData = (jsonData) => {
  console.log(jsonData);

  const formData = new FormData();

  for (const key in jsonData) {
    if (jsonData.hasOwnProperty(key)) {
      const value = jsonData[key];
      if(value && value !== undefined && value !== 'undefined' && value !== null && value !== 'null'){
        if (Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }
    }
  }
  console.log(formData);

  return formData;
};
export const createExcelFile = (base64Data) => {
  const excelBlob = new Blob([base64Data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const excelFileName = "output.xlsx";
  const link = document.createElement("a");
  link.href = URL.createObjectURL(excelBlob);
  link.download = excelFileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const compareJSON = (json1, json2) => {
  const result: any = {};

  function recurse(obj1, obj2, path = []) {
    for (const key in obj1) {
      if (obj2.hasOwnProperty(key)) {
        const newPath: any = [...path, key];

        if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
          recurse(obj1[key], obj2[key], newPath);
        } else {
          if (obj1[key] !== obj2[key]) {
            result[newPath[newPath.length - 1]] = obj2[key];
          }
        }
      }
    }
  }

  recurse(json1, json2);
  return result;
};
export const simplifyJSON = (json) => {
  function extractValue(obj) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (
        Array.isArray(value) &&
        value.length > 0 &&
        typeof value[0] === "object"
      ) {
        acc = { ...acc, ...extractValue(value[0]) };
      } else if (typeof value === "object" && value !== null) {
        acc = { ...acc, ...extractValue(value) };
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  return extractValue(json);
};
export const createPayloadUpdate = (json, keysToDelete) => {
  function removeKeys(obj) {
    if (Array.isArray(obj)) {
      return obj.map((item) => removeKeys(item));
    } else if (typeof obj === "object" && obj !== null) {
      Object.keys(obj).forEach((key) => {
        if (keysToDelete.includes(key)) {
          delete obj[key];
        } else {
          obj[key] = removeKeys(obj[key]);
        }
      });
    }
    return obj;
  }

  return removeKeys({ ...json });
};
const Functions = {
  useSetState,
  modelError,
  nanoid,
  removeEmptyValues,
  getNestedObjectValue,
  printDiv,
  capitalizeFirstLetter,
  timeConvert,
  jsonToFormData,
  createExcelFile,
  compareJSON,
  createPayloadUpdate,
};

export default Functions;

import React from 'react'

const ClientPovDashboard = () => {
  return (
    <div style={{height : "70%"}} className="dashboard_container d-flex justify-content-center align-items-center">
      <h5 className="h5 text-muted">Dashboard charts not implemented right now.</h5>
    </div>
  )
}

export default ClientPovDashboard
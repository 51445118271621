import { useEffect } from "react";
import History from "components/history/history.component";
import View from "components/view/view.component";
import Assets from "imports/assets.import";
import { Models } from "utils/imports.utils";
import { useSetState } from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";

export default function ViewProfile() {
  const [state, setState] = useSetState({
    data: {},
    deleteModal: false,
  });

  useEffect(() => {
    GetMe();
  }, []);
  const GetMe = async () => {
    try {
      let res: any;
      res = await Models.customer.getMe();
      const encryptedEmail = res?.data?.email;
      localStorage.setItem("email", encryptedEmail);
      localStorage.setItem("role", res?.data?.role);
      setState({ data: res.data });
    } catch (error) {
      console.log(error);
    }
  };
  const inputFields: IAddValues[] = [
    { label: "Full name", key: "fullName", type: "string" },
    { label: "Gender",key: "gender", type : "string"},
    { label: "Dob", key: "dob", type: "date", },
    { label: "Blood Group",key: "bloodGroup", type : 'string'},
    { label: "Mobile", key: "mobile", type: "string" },
    { label: "WhatsApp number", key: "wapNumber", type: "string" },
    { label: "Secondary mobile no", key: "secondaryMobileNo", type: "string" },
    { label: "Father name", key: "fatherName", type: "string" },
    { label: "Languages", key: "languages", type: "string"},
    { label: "Email", key: "email", type: "string" },
    { label: "Profile image", key: "userImage", type: "file" },
    { label: "Aadhaar document", key: "aadhaarDocument", type: "file" },
    { label: "Pan document", key: "panDocument", type: "file" },
    { label: "Control center ", key: "controlCenterId", type: "center" },
    { label: "Full Address", key:  "address[0].fullAddress", type: "string", isNested: true },
    { label: "State", key:  "address[0].state", type: "state", isNested: true },
    { label: "City", key:  "address[0].city", type: "city", isNested: true },
    { label: "Pincode", key:  "address[0].pinCode", type: "string", isNested: true },
    { label: "Landmark", key:  "address[0].landMark", type: "string", isNested: true },
    { label: "Building name", key:  "address[0].buildingName", type: "string", isNested: true },
  ];

  return (
    <div className="view_driver_container">
      <div className="view_driver_wrapper">
        <History path="profile" name={state?.data?.fullName} />
        <div className="view_driver_body_container">
          <View
            actions={[
              { link: `/profile/edit`, icon: "edit" },
            ]}
            data={state.data}
            values={inputFields}
            head={<ViewHeader />}
            // buttons={<ViewButton />}
            hasFiles
          />
        </div>
      </div>
    </div>
  );
  function ViewHeader() {
    return (
      <div className="view_head_left_container">
        <div className="view_head_image_conatiner">
          <img
            className="view_head_image"
            src={state?.data?.userImage || Assets.testPic}
            alt="head_image"
          />
        </div>
        <div className="view_head_title_container">
          <div className="view_head_title_wrapper">
            <div className="view_head_title">
              {state.data?.fullName} - {state.data?.role?.[0]}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

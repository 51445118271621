// authActions.js
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ADMIN_ROLES } from 'common_components/hoc/admin.pov';
import Models from 'imports/models.import';
import { toast } from 'react-toastify';
import encrypt from 'utils/encrypt';
import { modelError, toastifyError } from 'utils/functions.utils';
// import authService from '../../services/auth.service';

export const setLogin = createAsyncThunk("auth/login",
    async (values: any, { rejectWithValue, ...rest }) => {
        try {
            const [_,domain] = values?.email?.split("@");

            const method = domain == 'fullfily.com' ?  "INTERNAL" : "EXTERNAL"
            console.log("METHOD : ", method);
            // console.log("METHOD : ", method);
            const body = {
                ...values,
                method,
            };
            const jsonString = JSON.stringify(body);
            const data = await encrypt(jsonString);
            const login: any = await Models.auth.login({ data: data });
            console.log("CLIENT_INIT_MANDATE",login.message == "INIT_MANDATE_PASSWORD_CHANGE_REQUIRED");
            
            if(login.message == "INIT_MANDATE_PASSWORD_CHANGE_REQUIRED"){
                return {
                    user: {},
                    CLIENT_INIT_MANDATE : true,
                    CLIENT_INIT_MANDATE_EMAIL : values.email,
                }
            }else{
                if(login?.refreshToken && login?.access_token){
                    localStorage.setItem("refreshToken", login?.refreshToken);
                    localStorage.setItem("accessToken", login?.access_token);
                    let res: any;
                    res = await Models.customer.getMe();
                    const encryptedEmail = res?.data?.email;
                    localStorage.setItem("email", encryptedEmail);
                    localStorage.setItem("role", res?.data?.role);
                    return {
                        user: res?.data,
                        CLIENT_INIT_MANDATE : false,
                        CLIENT_INIT_MANDATE_EMAIL : "",
                    }
                }else{
                    toast(login?.message ?? "Can't Login")
                    return rejectWithValue(login?.message ?? "Can't Login");
                }
            }
        } catch (error) {
            console.log("Error =>", error);
            toastifyError(modelError(error));
            return rejectWithValue("some error happened");
        }
    }
)

export const setLogout = createAsyncThunk("auth/logout",
    async (_, { rejectWithValue }) => {
        try {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("email");
            localStorage.removeItem("role");
            toast("Logged out successfully");
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
)
export const getUser = createAsyncThunk("auth/getuser",
    async (_, { rejectWithValue, dispatch }) => {
        try {
            if (localStorage.getItem("accessToken")) {
                let res: any;
                res = await Models.customer.getMe();
                const encryptedEmail = res?.data?.email;
                localStorage.setItem("email", encryptedEmail);
                localStorage.setItem("role", res?.data?.role);
                return {
                    user: res?.data,
                }
            } else {
                return rejectWithValue("No login detected");
            }
        } catch (error) {
            return rejectWithValue("Unauthenticated");
        }
    }
)
// export const updateUserLocation = createAsyncThunk("auth/update/location",
//     async (address, { rejectWithValue }) => {
//         try {
//             return address
//         } catch (error) {
//             return rejectWithValue("Can't update user");
//         }
//     }
// )
// export const updateCheckinStatus = createAsyncThunk("auth/update/checkin",
//     async (checkin_data, { rejectWithValue }) => {
//         try {
//             return checkin_data
//         } catch (error) {
//             return rejectWithValue("Can't update user");
//         }
//     }
// )
// export const updateUser = createAsyncThunk("auth/update",
//     async (user_form_data, { rejectWithValue }) => {
//         try {
//             // const res = await authService.update_user(user_form_data)
//             // return res.data.data
//         } catch (error) {
//             return rejectWithValue("Can't update user");
//         }
//     }
// )

// export const deleteAccount = createAsyncThunk("auth/deleteAccount",
//     async (_, { rejectWithValue }) => {
//         try {
//             const isConfirmed = await confirmAsyncAlert({
//                 title: "Account Deletion",
//                 body: "Are you sure want to delete your account? This process couldn't be undone further.",
//                 yesText: "Delete",
//                 yesAction: async () => {
//                     // await authService.delete_account();
//                     await Storage.clear()
//                 },
//                 noText: "Cancel",
//                 noAction: () => { }
//             })
//             if (!isConfirmed) {
//                 return rejectWithValue("Cancelled")
//             }
//         } catch (error:any) {
//             return rejectWithValue(error.message);
//         }
//     }
// )
import Functions from "utils/functions.utils";
import instance from "../utils/axios.utils";
import { setManyVehicle, setVehicle } from "utils/redux.utils";

const vehicle = {
  createVehicle: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "vehicle";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  editVehicle: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "vehicle";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  getVehicle: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "vehicle";
        const response = await instance().get(url, { params: id });
        setVehicle(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getManyVehicle: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "vehicle";
        const response = await instance().get(url, { params: query });
        setManyVehicle(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getAllocatedDriver: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "vehicle/mapping";
        const response = await instance().get(url, { params: query });
        setManyVehicle(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  deleteVehicle: (payload) => {
    let promise = new Promise((resolve, reject) => {
      let url = "vehicle/sa/drop";
      instance()
        .delete(url, { data: payload })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  mobiliseVehicle: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "vehicle/mobilize";
      instance()
        .patch(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  immobiliseVehicle: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "vehicle/immobilize";
      instance()
        .patch(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  exportVehicle: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "vehicle?export=1";
        const response = await instance().get(url);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
};

export default vehicle;

import Main from 'common_components/hoc/main.hoc'
import React, { FC, Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { authSliceType } from 'store/slices/auth.slice';
import { ADMIN_ROLES } from './admin.pov';
import { CLIENT_ROLES } from './client.pov';

const GuestRoutes: FC<any> = () => {
  const role = localStorage.getItem("role") + "";
  const auth:authSliceType = useSelector((s:any)=>s.auth);
  const navigate = useNavigate();
  let redirect_url = "/dashboard"
  useEffect(()=>{
    console.log("Hello",auth.CLIENT_INIT_MANDATE);
    if(auth.CLIENT_INIT_MANDATE) navigate("/sign-up/reset_password_confirm", { state: auth.CLIENT_INIT_MANDATE_EMAIL });
  },[auth.CLIENT_INIT_MANDATE])

  if(ADMIN_ROLES.includes(role)){
    redirect_url = "/dashboard"
  }
  if(CLIENT_ROLES.includes(role)){
    redirect_url = "/client-pov/dashboard"
  }
  if (!auth.loggedIn) {
    return <Outlet />
  }
  return (
    <Navigate to={redirect_url} />
  )
}

export default GuestRoutes
import React, { FC } from 'react';

interface dividerProps {
  style?: any;
  color? : string
}
const Divider:FC<dividerProps> = ({
  style,
  color= "#E4E4E4"
})=> {
  
  return (
    <div className="divider_container">
      <div style={{margin : "10px 0" ,height: 0.2, opacity: 0.75, background :color , ...style }} ></div>
    </div>
  );
}

export default Divider
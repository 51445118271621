import { ADMIN_ROLES } from "common_components/hoc/admin.pov";
import { CLIENT_ROLES } from "common_components/hoc/client.pov";

export type sidebarRouteType = {
  boxIcon? : string;
  icon? : string,
  link : string,
  label : string,
}
const sidebarRoutes = ()=>{
  const role = `${localStorage.getItem('role')}`
  let routes:sidebarRouteType[] = [] 
  const admin_routes:sidebarRouteType[]  = [
    {
      boxIcon: "bxs-dashboard",
      link: "/dashboard",
      label: "Dashboard",
    },
    {
      icon: "hub",
      link: "/center",
      label: "Control center",
    },
    {
      boxIcon: "bx-user",
      link: "/user",
      label: "User",
    },
    {
      boxIcon: "bxs-store-alt",
      link: "/client",
      label: "Client",
    },
    
    {
      icon: "driver",
      link: "/driver",
      label: "Driver",
    },
    
    {
      icon: "vehicle",
      link: "/vehicle",
      label: "Vehicle",
    },
    
    {
      boxIcon: "bx-log-in-circle",
      link: "/checkin",
      label: "Checkin",
    },
    {
      boxIcon: "bx-purchase-tag",
      link: "/ticket",
      label: "Ticket",
    },
    // _NAV_ ̰
  ];

  const client_routes:sidebarRouteType[]  = [
    {
      boxIcon: "bxs-dashboard",
      link: "/client-pov/dashboard",
      label: "Client Dashboard",
    },
    {
      boxIcon: "bxs-store-alt",
      link: "/client-pov/store",
      label: "Store",
    },
  ];
  if(ADMIN_ROLES.includes(role)) {
    routes = [
      ...routes,
      ...admin_routes,
    ]
  }
  if(CLIENT_ROLES.includes(role)) {
    routes = [
      ...routes,
      ...client_routes,
    ]
  }
  return routes
}

export default sidebarRoutes
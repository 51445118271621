import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./view_vehicle.screen.scss";
import History from "components/history/history.component";
import View from "components/view/view.component";
import Button from "common_components/ui/button/button.ui";
import Assets from "imports/assets.import";
import { Models } from "utils/imports.utils";
import { useSetState, toastifyError, toastify } from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";
import DeletePopup from "components/delete_popup/delete_popup.component";
import CustomModal from "common_components/ui/modal/modal.component";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import ActionButton from "common_components/ui/action_button/action_button.ui";

export default function ViewVehicle() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const params = useParams();
  const pathArr = pathname.split("/");

  const [state, setState] = useSetState({
    data: {},
    deleteModal: false,
    driverAllocated: [],
    deallocateModal: false,
  });
  useEffect(() => {
    GetVehicle();
    getAllocatedDriver();
  }, []);
  const getAllocatedDriver = async () => {
    try {
      let res: any;
      const body: any = {
        vId: params.id,
      };
      res = await Models.vehicle.getAllocatedDriver(body);
      setState({ driverAllocated: res.data[0] });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };
  const GetVehicle = async () => {
    try {
      const response: any = await Models.vehicle.getVehicle({
        id: params.id,
      });
      setState({ data: response.data.data[0] });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const handleDelete = (data) => {
    setState({ id: data._id, deleteModal: true });
  };
  const handleImmobilise = (data: any) => {
    setState({ vhNo: data.vhNumber, rejectModal: true });
  };

  const handleMobilise = (data: any) => {
    setState({ vhNo: data.vhNumber, approvalModal: true });
  };
  const handleStatus = async (vhno, status) => {
    try {
      const body = {
        vhNo: vhno,
        partner: "INTELLICAR",
      };
      if (status === "MOBILISE") {
        const res: any = await Models.vehicle.mobiliseVehicle(body);
        toastify(res?.message?.vhDataResp?.data);
      }
      if (status === "IMMOBILISE") {
        const res: any = await Models.vehicle.immobiliseVehicle(body);
        toastify(res?.message?.vhDataResp?.data);
      }

      setState({
        rejectModal: false,
        approvalModal: false,
      });
      navigate("/vehicle");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const DeleteVehicle = async () => {
    try {
      await Models.vehicle.deleteVehicle({
        ids: [state.id],
        target: "delete",
        checkCreatedBy: false,
        hardDelParam: false,
        reverterParam: false,
      });
      setState({ id: "", deleteModal: false });
      navigate("/vehicle");
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const handleViewAllottedDriver = () => {
    navigate(`/driver/view/${state?.driverAllocated?.driverId?.userId}`);
  };
  const handleDeAllocation = async () => {
    try {
      const res: any = await Models.driver.deAllocation(
        state?.driverAllocated?._id
      );
      GetVehicle();
      toastify("Driver de-allocated successfully!");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const inputFieldsworking: IAddValues[] = [
    { label: "Model", key: "vhModel", type: "string" },
    { label: "Owner Name", key: "ownerName", type: "string" },
    { label: "Type", key: "vhType", type: "string" },
    { label: "State", key: "state", type: "string" },
    { label: "City", key: "city", type: "string" },
    { label: "Number", key: "vhNumber", type: "string" },
    { label: "Company", key: "vhCompany", type: "string" },
    { label: "RC Number", key: "rcNumber", type: "string" },
    { label: "Insurance Number", key: "insuranceNumber", type: "string" },
    { label: "Registration city", key: "city", type: "string" },
    { label: "Bike image", key: "vhImage", type: "passbook" },
    { label: "Insurance image", key: "insuranceImage", type: "passbook" },
    { label: "Rc book front", key: "rcImageF", type: "passbook" },
    { label: "Rc book back", key: "rcImageB", type: "passbook" },
  ];
  const statFields: IAddValues[] = [
    { label: "Battery temperature", key: "batteryTemperature", type: "number" },
    { label: "Battery voltage", key: "batteryVoltage", type: "number" },
    { label: "SOC", key: "soc", type: "number" },
    { label: "Current", key: "current", type: "number" },
    { label: "Ignition Status", key: "ignitionStatus", type: "string" },
    {
      label: "Mobility",
      key: "mobility",
      type: "number",
    },
    { label: "Dev Battery", key: "devBattery", type: "number" },
    {
      label: "Vehicle Battery",
      key: "vehBattery",
      type: "number",
    },
  ];
  return (
    <div className="view_vehicle_container">
      <div className="view_vehicle_wrapper">
        <History name={state.data?.vhModel} path={"view"} />
        <div className="view_vehicle_body_container">
          <View
            actions={[
              { link: `/vehicle/edit/${id}`, icon: "edit" },
              { link: "/", icon: "delete", onClick: handleDelete },
            ]}
            data={state.data}
            values={inputFieldsworking}
            head={<ViewHeader />}
            // buttons={<ViewButton />}
            hasFiles
          />
          {/* <View
            actions={[]}
            data={state?.statData}
            values={statFields}
            // buttons={<ViewButton />}
          /> */}
          <div className="view-action-req">
            <div className="view-actio-h">Take Actions</div>
            <div className="accept-decline">
              <ActionButton
                icon={"approve"}
                onClick={handleMobilise}
                label={"Mobilise"}
                className="req-button"
              />
              <ActionButton
                icon={"decline"}
                onClick={handleImmobilise}
                label={"Immobilise"}
                className="req-button"
              />
            </div>
          </div>
        </div>
      </div>
      {/* reject Modal */}
      <CustomModal
        center
        open={state.rejectModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ rejectModal: false })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">Immobilise this?</div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ rejectModal: false });
              }}
              className="reject_cancel_button"
            >
              No
            </div>
            <div
              onClick={() => handleStatus(state.vhNumber, "IMMOBILISE")}
              className="reject_button"
            >
              Yes
            </div>
          </div>
        </div>
      </CustomModal>
      {/* reject modal end */}
      {/* approve modal */}
      <CustomModal
        center
        open={state.approvalModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ approvalModal: false })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">Mobilise this?</div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ approvalModal: false });
              }}
              className="reject_cancel_button"
            >
              No
            </div>
            <div
              onClick={() => handleStatus(state.vhNumber, "MOBILISE")}
              className="reject_button"
            >
              Yes
            </div>
          </div>
        </div>
      </CustomModal>
      {/* approve modal end */}
      <CustomModal
        center
        open={state.deleteModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deleteModal: false })}
      >
        <DeletePopup
          onPress={DeleteVehicle}
          onCancel={() => setState({ deleteModal: false })}
        />
      </CustomModal>
      <CustomModal
        center
        open={state.deallocateModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deallocateModal: false })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">
            Are you sure you want to deallocate vehicle?
          </div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ deallocateModal: false });
              }}
              className="reject_cancel_button"
            >
              No
            </div>
            <div onClick={() => handleDeAllocation()} className="reject_button">
              Yes
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );

  function ViewHeader() {
    return (
      <div className="view_head_left_container">
        <div className="view_head_title_container">
          <div className="view_head_title_wrapper">
            <div className="view_head_title">{state.data?.vhModel}</div>
            {state.driverAllocated && (
              <div className="allocated_to">
                Allocated to - {state?.driverAllocated?.driverId?.dNumber}
                <img
                  src={Assets["view"]}
                  onClick={() => handleViewAllottedDriver()}
                />
                <img
                  src={Assets["delete"]}
                  onClick={() => setState({ deallocateModal: true })}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

import React, { useEffect } from "react";
import "./select.component.scss";
import { useSetState } from "utils/functions.utils";
import OverlayClose from "../../common_components/ui/overlay_close/overlay_close.component";
import { Models } from "utils/imports.utils";
import Assets from "imports/assets.import";
import { Dropdown } from "primereact/dropdown";
interface customInputProps {
  className?: string;
  label?: string;
  placeholder?: string;
  name: string;
  org?: string;
  onChange: any;
  type: "state" | "driver" | "vehicle" | "user" | "center";
  value?: string;
  optional?: boolean;
}
export default function œœSelect(props: customInputProps) {
  const {
    label,
    name,
    className,
    placeholder,
    onChange,
    type,
    value,
    optional,
  } = props;

  const [state, setState] = useSetState({
    open: false,
    openArea: false,
    isVisible: false,
    data: [],
    cities: [],
    value: "",
    id: "",
    search: "",
    limit: 1000,
    state: "",
    defaultLocation: { lat: 13.0739183, lng: 80.1907184 },
    visibleStates: [],
    statesData: [],
    loading: false,
  });

  const fetchData = async () => {
    try {
      setState({ loading: true });
      await Action[type]();
    } catch (err) {
      console.log("ERR in SELECT COMPONENT STORE", err);
    } finally {
      setState({ loading: false });
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (type === "state" && value && !state.value) {
      handleSelect(value);
    }
    if (type === "driver" && !value) {
      setState({ value });
    }
    if (type === "vehicle" && !value) {
      setState({ value });
    }
    if (type === "user" && !value) {
      setState({ value });
    }
    if (type === "center" && !value) {
      setState({ value });
    }
  }, [value]);

  const getState = async () => {
    try {
      const apiUrl =
        "https://api-dev.users.fullfily.com/api/v1/smc/location?key=st";

      const response = await fetch(apiUrl, {
        method: "GET",
      });

      if (response.ok) {
        //Data filtration
        const responseData = await response.json();
        const temp = responseData?.data?.map((item: string) => ({
          label: item,
          value: item,
        }));
        setState({ statesData: temp, visibleStates: temp });
      } else {
        console.error(
          "Failed to fetch states:",
          response.status,
          response.statusText
        );
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  const GetManyDriver = async () => {
    const body: any = {};
    try {
      const res: any = await Models.driver.getManyDriver(body);
      const temp = res?.data?.map((item: any) => ({
        label: item?.name ?? "-- No name --",
        value: item?._id,
      }));
      setState({ data: temp });
      // if (state.search.length > 0) {
      //   body.search = state.search;
      // }
      // setState({ data: res?.data });
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  const GetManyVehicle = async () => {
    const body: any = {};
    if (state.search.length > 0) {
      body.search = state.search;
    }
    try {
      const res: any = await Models.vehicle.getManyVehicle(body);
      const temp = res?.data?.data?.map((item: any) => ({
        label: item?.vhNumber ?? "-- No name --",
        value: item?._id,
        modal: item?.vhModel,
      }));
      setState({ data: temp });
    } catch (err) {
      console.log(err);
    }
  };
  const GetManyUser = async () => {
    const body: any = {
      adm: "ls",
    };
    if (state.search.length > 0) {
      body.search = state.search;
    }
    try {
      const res: any = await Models.user.getManyUser(body);
      const temp = res?.data?.map((item: any) => ({
        label: item?.fullName ?? "-- No name --",
        value: item?._id,
        email: item?.email,
      }));
      setState({ data: temp });
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  const GetManyCenter = async () => {
    const body: any = {};
    if (state.search.length > 0) {
      body.search = state.search;
    }
    try {
      const res: any = await Models.center.getManyCenter(body);
      const temp = res?.data?.map((item: any) => ({
        label: item?.controlCenterName,
        value: item?._id,
        email: item?.centerInCharge?.email,
      }));
      setState({ data: temp });
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  const Action = {
    state: getState,
    driver: GetManyDriver,
    vehicle: GetManyVehicle,
    user: GetManyUser,
    center: GetManyCenter,
  };
  const getValue = (data) => {
    if (type === "state") {
      return data;
    } else if (type === "driver") {
      return data.userId.name;
    } else if (type === "vehicle") {
      return data.vhNumber;
    } else if (type === "user") {
      return data.fullName;
    } else if (type === "center") {
      return data._id; // Return _id for the center type
    } else {
      return data.name ? data.name : "";
    }
  };

  const handleSelect = (data) => {
    setState({
      value: getValue(data),
      isVisible: false,
      open: false,
      search: "",
    });

    if (type === "state") {
      onChange(data);
      setState({ state: data });
    } else if (type === "driver") {
      onChange(data.userId._id, data.userId.name);
    } else if (type === "vehicle") {
      onChange(data._id, data.vhNumber);
    } else if (type === "user") {
      onChange(data._id, data.fullName);
    } else if (type === "center") {
      onChange(data._id); // Pass only the _id for the center type
    } else {
      onChange(data);
    }
  };

  const handleChange = (e) => {
    const temp = state.data.filter((item) => {
      return item.indexOf(state.search) !== -1;
    });
    setState({
      search: e.target.value,
      value: e.target.value,
      visibleStates: temp,
    });
    if (!e.target.value) {
      onChange("");
    }
  };
  const handleVisible = (e) => {
    setState({ isVisible: true, search: "" });
  };
  const userValueTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>
          {option?.email ? "Email : " + option?.email : "Select a user"}
        </div>
      </div>
    );
  };
  const userItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>Name : {option.label}</div>
        <div>Email : {option.email}</div>
      </div>
    );
  };
  const vehicleItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>Modal : {option.modal}</div>
        <div>Vh Number : {option.label}</div>
      </div>
    );
  };
  return (
    // <div className="select_address">

    // </div>
    <div className="input_container">
      <div className="label_container caption2">
        {label}
        {!optional && <span className="required">*</span>}
      </div>
      {type === "state" && (
        // @ts-ignore
        <Dropdown
          loading={state.loading}
          filter
          value={value}
          onChange={(e) => onChange(e.value)}
          options={state.statesData ?? []}
          optionLabel="label"
          placeholder={state.loading ? "Fetching Data..." : "Select a State"}
          style={{ width: "100%" }}
        />
      )}
      {type === "center" && (
        // @ts-ignore
        <Dropdown
          loading={state.loading}
          filter
          value={typeof value === "string" ? value : (value as any)?._id}
          onChange={(e) => onChange(e.value)}
          options={state.data ?? []}
          optionLabel="label"
          placeholder={
            state.loading ? "Fetching Data..." : "Select control center"
          }
          style={{ width: "100%" }}
        />
      )}
      {type === "driver" && (
        // @ts-ignore
        <Dropdown
          loading={state.loading}
          filter
          value={value}
          onChange={(e) => onChange(e.value)}
          options={state.data ?? []}
          optionLabel="label"
          placeholder={state.loading ? "Fetching Data..." : "Select a driver"}
          style={{ width: "100%" }}
        />
      )}
      {type === "user" && (
        // @ts-ignore
        <Dropdown
          loading={state.loading}
          filter
          value={typeof value === "string" ? value : (value as any)?._id}
          onChange={(e) => onChange(e.value)}
          options={state.data ?? []}
          optionLabel="label"
          valueTemplate={userValueTemplate}
          itemTemplate={userItemTemplate}
          placeholder={state.loading ? "Fetching Data..." : "Select a user"}
          style={{ width: "100%" }}
        />
      )}
      {type === "vehicle" && (
        // @ts-ignore
        <Dropdown
          loading={state.loading}
          filter
          value={value}
          onChange={(e) => onChange(e.value)}
          options={state.data ?? []}
          filterBy="label,modal"
          optionLabel="label"
          itemTemplate={vehicleItemTemplate}
          placeholder={state.loading ? "Fetching Data..." : "Select a vehicle"}
          style={{ width: "100%" }}
        />
      )}
    </div>
  );
}

import InputField from "common_components/ui/field/field.ui";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Validation from "utils/validation.utils";
import React, { useRef, useEffect, useState } from "react";
import "./add.component.scss";
import {
  getNestedObjectValue,
  toastifyError,
  useSetState,
} from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";
import CustomSelect from "common_components/ui/select/select.ui";
import Select from "components/select/select.component";
import ValidationError from "common_components/ui/error/error.ui";
import _ from "lodash";
import Divider from "common_components/ui/divider/divider.ui";
import FileInput from "common_components/ui/file_input/file_input.ui";
import MultiUserSelect from "components/multiselect/multiselect.component";
import MapPicker from "common_components/ui/map_picker/map_picker.ui";
import Models from "imports/models.import";

interface addProps {
  data: any;
  values: IAddValues[];
  actions: { link: string; icon: string }[];
  hasFiles?: boolean;
  buttons?: any;
  title: string;
  logo?: any;
  initialValues: any;
  validationSchema: string;
  onSubmit: any;
  getForm?: any;
  optional?: boolean;
}
export default function Add(props: addProps) {
  const {
    data,
    values,
    hasFiles,
    buttons,
    logo,
    initialValues,
    validationSchema,
    onSubmit,
    getForm,
  } = props;
  const formikRef: any = useRef(null);
  const getEditableValues = () => {
    let newData = {};
    if (data) {
      Object.keys(data).forEach((item) => {
        newData[item] = data[item] || initialValues[item];
      });
      return newData;
    }
    return data;
  };

  const [state, setState] = useSetState({
    form: data,
    role: "",
    state: "",
    cities: [],
    cityLoading: false,
  });
  const [publicUrl, setPublicUrl] = useState([]);

  const [tempFile, setTempFile] = useState<{
    url: string | null;
    name: string | null;
  }>({
    url: null,
    name: null,
  });
  useEffect(() => {
    getCity();
  }, [state.state]);

  useEffect(() => {
    values.forEach(({ key, type }) => {
      if (type === "state" && (data[key] || getNestedObjectValue(data, key))) {
        {
          setState({ state: data[key] || getNestedObjectValue(data, key) });
        }
      }
    });
  }, [data]);
  useEffect(() => {
    setState({ form: getEditableValues() });
  }, [data]);

  useEffect(() => {
    values.forEach(({ key, type }) => {
      if (
        type === "file" &&
        (data[key] || getNestedObjectValue(data, key)) &&
        !isPublicURL(data[key]) &&
        !state[`${key}PublicURL`]
      ) {
        convertToPublicUrl(key, getNestedObjectValue(data, key));
      }
    });
  }, [data]);
  const convertToPublicUrl = async (key, url) => {
    try {
      const body: any = {
        bucketName: process.env.REACT_APP_SIGNED_BUCKET_KEY,
        file: url,
        expiresIn: 3000,
      };
      const response: any = await Models.signedUrl.getSignedUrl(body);

      // Check if the response has a valid link
      if (response?.data?.link) {
        setPublicUrl((prevState) => ({
          ...prevState,
          [`${key}PublicURL`]: response?.data?.link,
        }));
      } else {
        console.error("Invalid public URL in the response:", response);
        // Handle the error or log it as needed
      }
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const isPublicURL = (url) => {
    return url?.startsWith("https://");
  };
  const conditionCheck = (condition) => {
    if (!condition) return true;
    if (condition(state.form)) {
      return true;
    }
  };

  const handleChange = (form) => {
    values.forEach(({ key, type }) => {
      if (type === "state" && (form[key] || getNestedObjectValue(form, key))) {
        {
          setState({ state: form[key] || getNestedObjectValue(form, key) });
          console.log(getNestedObjectValue(form, key));
        }
      }
    });
    setState({ form });
    if (getForm) {
      getForm(form);
    }
  };
  const getCity = async () => {
    setState({ cityLoading: true });
    try {
      if (state?.state?.length > 0) {
        const apiUrl = `https://api-dev.users.fullfily.com/api/v1/smc/location?key=ct&value=${encodeURIComponent(
          state.state
        )}`;

        const response = await fetch(apiUrl, {
          method: "GET", // Use the GET method for retrieving data
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const citiesData = await response.json();

          const cities = _.chain(citiesData.data)
            .map((e: any) => ({
              label: e.toUpperCase(),
              value: e,
            }))
            .compact()
            .uniqBy("value")
            .orderBy("label")
            .value();

          setState({ cities: cities });
        } else {
          // Handle the error if the response is not okay
          console.error(
            "Failed to fetch cities:",
            response.status,
            response.statusText
          );
        }
      }
    } catch (err: any) {
      console.error(err);
      toastifyError("Can't fetch cities !");
    } finally {
      setState({ cityLoading: false });
    }
  };

  const value = getEditableValues() || initialValues;

  return (
    <div className="add_container">
      <div className="add_wrapper">
        <Formik
          ref={formikRef}
          onSubmit={onSubmit}
          validate={handleChange}
          initialValues={value}
          validationSchema={Validation[validationSchema]}
          enableReinitialize
        >
          <Form autoComplete="off">
            {logo && logo}
            <div className="add_field_body_container">
              <div className="add_field_body_wrapper">
                {values.map(
                  ({
                    label,
                    key,
                    type,
                    options,
                    condition,
                    additionalInfo,
                    secondaryKey,
                    items,
                    optional,
                    hide,
                  }) =>
                    !hide
                      ? conditionCheck(condition) && (
                          <>
                            {type === "string" && (
                              <div className="add_field_container">
                                <InputField
                                  name={key}
                                  label={label}
                                  type="text"
                                  optional={optional}
                                />
                              </div>
                            )}
                            {type === "password" && (
                              <div className="add_field_container">
                                <InputField
                                  name={key}
                                  label={label}
                                  type="password"
                                />
                              </div>
                            )}
                            {type === "number" && (
                              <div className="add_field_container">
                                <InputField
                                  name={key}
                                  label={label}
                                  type="number"
                                />
                                {additionalInfo && (
                                  <div className="additional_field">
                                    {additionalInfo}
                                  </div>
                                )}
                              </div>
                            )}
                            {type === "date" && (
                              <div className="add_field_container">
                                <InputField
                                  name={key}
                                  label={label}
                                  type="date"
                                  value={data[key]}
                                />
                              </div>
                            )}
                            {type === "birthdate" && (
                              <div className="add_field_container">
                                <InputField
                                  name={key}
                                  label={label}
                                  type="birthdate"
                                  value={data[key]}
                                />
                              </div>
                            )}
                            {type === "dateRange" && (
                              <div className="add_field_container">
                                <InputField
                                  name={key}
                                  label={label}
                                  type="dateRange"
                                  value={data[key]}
                                />
                              </div>
                            )}
                            {type === "select" && (
                              <div className="add_field_container">
                                <CustomSelect
                                  name={key}
                                  label={label}
                                  placeholder={label}
                                  options={options}
                                  optional={optional}
                                />
                                {/* <ErrorMessage
                              name={key}
                              component={ValidationError}
                            /> */}
                              </div>
                            )}
                            {type === "city" && (
                              <div className="add_field_container">
                                <CustomSelect
                                  loading={state.cityLoading}
                                  name={key}
                                  label={"City"}
                                  placeholder={"Select a City"}
                                  options={state.cities}
                                  optional={optional}
                                />
                              </div>
                            )}
                            {type === "textarea" && (
                              <div className="add_field_container">
                                <InputField
                                  className="textarea_wrapper"
                                  name={key}
                                  label={label}
                                  type="textarea"
                                  optional={optional}
                                />
                              </div>
                            )}
                            {type === "driver" && (
                              <div className="add_field_container">
                                <Field name={key}>
                                  {({ field, form }) => (
                                    <Select
                                      name={key}
                                      onChange={(val) =>
                                        form.setFieldValue(key, val)
                                      }
                                      placeholder="Select driver"
                                      label={label}
                                      type="driver"
                                      value={data?.[key]?.name || ""}
                                    />
                                  )}
                                </Field>
                                <ErrorMessage
                                  name={key}
                                  component={ValidationError}
                                />
                              </div>
                            )}
                            {type === "vehicle" && (
                              <div className="add_field_container">
                                <Field name={key}>
                                  {({ field, form }) => (
                                    <Select
                                      name={key}
                                      onChange={(val) => {
                                        form.setFieldValue(key, val);
                                        console.log("VEHICLE", key, val);
                                      }}
                                      placeholder="Select vehicle"
                                      label={label}
                                      type="vehicle"
                                      value={field.value}
                                      optional={optional}
                                    />
                                  )}
                                </Field>
                                <ErrorMessage
                                  name={key}
                                  component={ValidationError}
                                />
                              </div>
                            )}
                            {type === "user" && (
                              <div className="add_field_container">
                                <Field name={key}>
                                  {({ field, form }) => (
                                    <Select
                                      name={key}
                                      onChange={(val) =>
                                        form.setFieldValue(key, val)
                                      }
                                      placeholder="Select user"
                                      label={label}
                                      type="user"
                                      value={field.value}
                                      optional={optional}
                                    />
                                  )}
                                </Field>
                                <ErrorMessage
                                  name={key}
                                  component={ValidationError}
                                />
                              </div>
                            )}
                            {type === "center" && (
                              <div className="add_field_container">
                                <Field name={key}>
                                  {({ field, form }) => (
                                    <Select
                                      name={key}
                                      onChange={(val) => {
                                        form.setFieldValue(key, val);
                                      }}
                                      label={label}
                                      type="center"
                                      placeholder="Select control center "
                                      value={field.value}
                                    />
                                  )}
                                </Field>
                                <ErrorMessage
                                  name={key}
                                  component={ValidationError}
                                />
                              </div>
                            )}

                            {type === "multiselect" && (
                              <div className="add_field_container">
                                <Field name={key}>
                                  {({ field, form }) => (
                                    <MultiUserSelect
                                      items={items as any}
                                      name={key}
                                      onChange={(val) => {
                                        form.setFieldValue(key, val);
                                      }}
                                      placeholder="Select users"
                                      label={label}
                                      value={field.value}
                                    />
                                  )}
                                </Field>
                                <ErrorMessage
                                  name={key}
                                  component={ValidationError}
                                />
                              </div>
                            )}
                            {type === "state" && (
                              <div className="add_field_container">
                                <Field name={key}>
                                  {({ field, form }) => (
                                    <Select
                                      name={key}
                                      onChange={(val) => {
                                        form.setFieldValue(key, val);
                                      }}
                                      label={label}
                                      type="state"
                                      placeholder="Select state"
                                      value={
                                        field.value ||
                                        data[key] ||
                                        getNestedObjectValue(data, key)
                                      }
                                    />
                                  )}
                                </Field>
                                <ErrorMessage
                                  name={key}
                                  component={ValidationError}
                                />
                              </div>
                            )}
                            {type === "array" && (
                              <div className="add_field_container">
                                <InputField
                                  name={key}
                                  label={label}
                                  type="array"
                                  placeholder={`${label} values separated by commas`}
                                />
                              </div>
                            )}
                            {type === "map" && (
                              <div className="add_field_container">
                                <Field name={key}>
                                  {({ field, form }) => (
                                    <MapPicker
                                      label={label}
                                      value={data[key] || undefined}
                                      onChange={(val) => {
                                        form.setFieldValue(key, val);
                                      }}
                                      placeholder="Pick store location"
                                    />
                                  )}
                                </Field>
                                <ErrorMessage
                                  name={key}
                                  component={ValidationError}
                                />
                              </div>
                            )}
                          </>
                        )
                      : null
                )}
              </div>
            </div>
            {hasFiles && (
              <>
                <Divider />
                <div className="add_field_body_container">
                  <div className="add_field_body_wrapper">
                    {values.map(
                      ({
                        label,
                        key,
                        type,
                        secondaryKey,
                        file_bucket_url,
                        hide,
                        optional,
                      }) =>
                        !hide ? (
                          <>
                            {type === "file" && (
                              <div className="add_field_container">
                                {!isPublicURL(
                                  data[key] || getNestedObjectValue(data, key)
                                ) ? (
                                  publicUrl[`${key}PublicURL`] && (
                                    <div className="view_field img">
                                      <img
                                        src={publicUrl[`${key}PublicURL`]}
                                        className="view_file"
                                      />
                                    </div>
                                  )
                                ) : (
                                  <div className="view_field img">
                                    <img
                                      src={
                                        data[key] ||
                                        getNestedObjectValue(data, key)
                                      }
                                      className="view_file"
                                    />
                                  </div>
                                )}
                                <Field name={key}>
                                  {({ field, form, meta }) => (
                                    <FileInput
                                      label={label}
                                      fileKey={key}
                                      name={field.name}
                                      secondaryKey={secondaryKey}
                                      file_bucket_url={file_bucket_url}
                                      onChangeFile={(file) => {
                                        console.log(file);

                                        form.setFieldValue(field.name, file);
                                      }}
                                      optional={optional}
                                    />
                                  )}
                                </Field>
                                <ErrorMessage
                                  name={key}
                                  component={ValidationError}
                                />
                              </div>
                            )}
                          </>
                        ) : null
                    )}
                  </div>
                </div>
              </>
            )}
            {buttons && buttons}
          </Form>
        </Formik>
      </div>
    </div>
  );
}

import React, { useEffect, useRef } from "react";
import {
  useSetState,
  toastifyError,
  printDiv,
  createExcelFile,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import "react-responsive-modal/styles.css";
import DeletePopup from "components/delete_popup/delete_popup.component";
import Table from "components/table/table.component";
import Button from "common_components/ui/button/button.ui";
import Search from "common_components/ui/search/search.ui";
import CustomModal from "common_components/ui/modal/modal.component";
import { useNavigate } from "react-router-dom";
import "./vehicle.screen.scss";
import QRCodeComponent from "components/qr_code/qr_code.component";
import _ from "lodash";
import SelectDropdown from "common_components/ui/select_dropdown/select_dropdown.component";
import Filter from "common_components/ui/filter/filter.ui";
import Pagination from "components/pagination/pagination.component";
import pako from "pako";
import { Buffer } from "buffer";

export default function Vehicle() {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  //Ref
  const componentRef = useRef(null);

  //state
  const [state, setState] = useSetState({
    selectedFilter: "Working",
    data: [],
    search: "",
    openModal: false,
    viewModal: false,
    deleteModal: false,
    loading: true,
    qrcode: false,
    isOpenModel: false,
    filterStatus: "",
    filterCompany: "",
    dateRange: "",
    toggleFilter: false,
    currentPage: 1,
    totalItems: 0,
  });

  const thead_working = [
    { head: "Date", key: "created_at", type: "date" },
    { head: "Model", key: "vhModel" },
    { head: "State", key: "state" },
    { head: "City", key: "city" },
    { head: "Vehicle type", key: "vhType" },
    { head: "Vehicle ID", key: "vId" },
    { head: "Number", key: "vhNumber" },
    { head: "Charge", key: "charge_percentage" },
    { head: "Vehicle status", key: "allocationStatus" },
  ];
  const status = [
    { label: "Allotted", value: "ALLOTTED" },
    { label: "Non Allotted", value: "NON_ALLOTTED" },
    { label: "All Status", value: 0 },
  ];
  // hooks
  useEffect(() => {
    GetManyData();
  }, [
    state.search,
    state.filterStatus,
    state.dateRange,
    state.filterCompany,
    state.currentPage,
  ]);

  const GetManyData = async () => {
    try {
      let res: any;
      const body: any = {
        page: state.currentPage,
        pageSize: 10,
      };
      if (state.search?.length > 0) {
        body.search = state.search;
      }
      if (state.filterStatus?.length > 0) {
        body.allocationStatus = state.filterStatus;
      }
      if (state.filterCompany?.length > 0) {
        body.vhCompany = state.filterCompany;
      }
      res = await Models.vehicle.getManyVehicle(body);
      setState({
        totalItems: res?.count,
        data: res?.data?.data,
      });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };
  const getAllVehicle = async () => {
    try {
      const res: any = await Models.vehicle.getManyVehicle({});

      const uniqueCompanies: any = _.uniqBy(res.data.data, "vhCompany");

      let data: any = uniqueCompanies.map((vehicle) => ({
        label: vehicle.vhCompany,
        value: vehicle.vhCompany,
      }));

      data = _.orderBy(data, ["label"]);
      data.unshift({
        label: "ALL COMPANY",
        value: 0,
      });

      setState({ vehicleData: data });
    } catch (err: any) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllVehicle();
  }, []);
  const DeleteVehicle = async () => {
    try {
      await Models.vehicle.deleteVehicle({
        ids: [state.id],
        target: "delete",
        checkCreatedBy: false,
        createdByCheck: false,
        hardDelParam: false,
        reverterParam: false,
      });
      setState({ id: "", deleteModal: false });
      GetManyData();
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const handleView = (data) => {
    navigate(`/vehicle/view/${data?._id}`);
  };
  const handleQRCode = (data) => {
    setState({
      vehicle_id: data._id,
      state: data?.state,
      city: data?.city,
      qrcode: true,
    });
  };
  const handleDelete = (data) => {
    setState({ id: data._id, deleteModal: true });
  };
  const handlePageChange = (page: number) => {
    setState({ currentPage: page });
  };
  const handleExport = async () => {
    const res: any = await Models.vehicle.exportVehicle();
    const compressedString = res.data.data;
    const compressedBuffer = Buffer.from(compressedString, "base64");
    const base64String = pako.ungzip(compressedBuffer, { to: "base64" });
    createExcelFile(base64String);
  };

  return (
    <div className="vehicle_screen">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="head h3">Vehicle</div>
        </div>
        <div className="search_wrapper">
          <Search
            placeholder={"Search by Vehicle ID, State, City, Vehicle Number"}
            value={state.search}
            onChange={(search) =>
              setState({ search, currentPage: 1, totalItems: 0 })
            }
          />
          <div className="button_group">
            <Filter
              onClick={() => setState({ toggleFilter: !state.toggleFilter })}
            />
            <Button
              className="button-block"
              value={"New Vehicle"}
              onClick={() => {
                navigate("/vehicle/add");
              }}
              boxIcon="bx-plus"
            />
            <Button
              value="Export"
              className="button-block"
              onClick={() => {
                handleExport();
              }}
            />
          </div>
        </div>
      </div>
      {state.toggleFilter && (
        <div className="vehicle_filter_container">
          <div className="vehicle_filter_dropdown_wrapper">
            <div className="vehicle_filter_dropdown_item">
              <SelectDropdown
                notfound={"No status found"}
                placeholder="Status"
                data={status}
                onChange={(filterStatus: any) =>
                  setState({
                    filterStatus: filterStatus.value,
                    currentPage: 1,
                    totalItems: 0,
                  })
                }
                value={state.filterStatus}
              />
            </div>
            <div className="vehicle_filter_dropdown_item">
              <SelectDropdown
                notfound={"No company found"}
                placeholder="Company"
                data={state.vehicleData}
                onChange={(filterCompany: any) =>
                  setState({
                    filterCompany: filterCompany.value,
                    currentPage: 1,
                    totalItems: 0,
                  })
                }
                value={state.filterCompany}
              />
            </div>
          </div>
        </div>
      )}
      <div className="vehicletable">
        <Table
          data={state.data}
          loading={state.loading}
          totalDocs={state.totalDocs}
          theads={thead_working}
          link="vehicle"
          actions={
            role === "SA"
              ? [
                  {
                    icon: "qrcode",
                    onClick: handleQRCode,
                  },
                  {
                    icon: "view",
                    onClick: handleView,
                  },
                  {
                    icon: "delete",
                    onClick: handleDelete,
                  },
                ]
              : [
                  {
                    icon: "qrcode",
                    onClick: handleQRCode,
                  },
                  {
                    icon: "view",
                    onClick: handleView,
                  },
                ]
          }
          loadMore={() => {}}
          imageKey="bike_image"
        />
      </div>
      <div className="driver_pagination">
        <Pagination
          totalItems={state.totalItems}
          itemsPerPage={10}
          currentPage={state.currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <CustomModal
        center
        open={state.deleteModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deleteModal: false })}
      >
        <DeletePopup
          onPress={DeleteVehicle}
          onCancel={() => setState({ deleteModal: false })}
        />
      </CustomModal>
      <CustomModal
        center
        open={state.qrcode}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ qrcode: false })}
      >
        <QRCodeComponent
          ref={componentRef}
          text={JSON.stringify({
            id: state.vehicle_id,
            state: state.state,
            city: state.city,
          })}
        />
        <div className="button_container">
          <Button
            onClick={() => {
              setState({ qrcode: false });
              window.print();
            }}
            buttonType="button"
            value="Print"
          />
        </div>
      </CustomModal>
      <CustomModal
        center
        open={state.openModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ openModal: false })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">You do not have access to this!</div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ openModal: false });
              }}
              className="reject_cancel_button"
            >
              Okay
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

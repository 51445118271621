import auth from "models/auth.model";
import driver from "models/driver.model";
import customer from "models/customer.model";
import organization from "models/organization.model";
import vehicle from "models/vehicle.model";
import store from "models/store.model";
import user from "models/user.model";
import center from "models/center.model";
import checkin from "models/checkin.model";
import signedUrl from "models/signedUrl.model";
// _NMI_

export const Models = {
  auth,
  organization,
  customer,
  driver,
  vehicle,
  store,
  user,
  center,
  checkin,
  signedUrl,
  // _NM_
};

export default Models;

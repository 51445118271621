import React, { useEffect } from "react";
import Functions, {
  useSetState,
  useQuery,
  toastifyError,
  createExcelFile,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import "./checkin.screen.scss";
import "react-responsive-modal/styles.css";
import Table from "components/table/table.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import DateRangePicker from "common_components/ui/date_range_picker/date_range.component";
import SelectDropdown from "common_components/ui/select_dropdown/select_dropdown.component";
import Button from "common_components/ui/button/button.ui";
import Filter from "common_components/ui/filter/filter.ui";
import Search from "common_components/ui/search/search.ui";
import Pagination from "components/pagination/pagination.component";
import pako from "pako";
import { Buffer } from "buffer";

export default function Checkin() {
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();
  const pathArr = pathname.split("/");
  const driverCheckin = params.id;
  console.log(params);

  const [state, setState] = useSetState({
    data: [],
    search: "",
    toggleFilter: false,
    currentPage: 1,
    totalItems: 0,
    filterStatus: "",
    filterVehicle: "",
    filterDriver: "",
    checkinVehicleData: [],
    checkinDriverData: [],
  });
  const status = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "in-active" },
    { label: "Break", value: "break" },
    { label: "All Status", value: 0 },
  ];
  const thead = [
    { head: "Date", key: "createdAt", type: "date-only" },
    { head: "Driver name", key: "createdBy.fullName", isNested: true },
    { head: "Mobile", key: "createdBy.mobile", isNested: true },
    { head: "Driver no.", key: "driverId.dNumber", isNested: true },
    { head: "Vehicle number.", key: "vehicleId.vhNumber", isNested: true },
    { head: "City", key: "city" },
    { head: "Checkin time", key: "vehicleCheckInTime", type: "date" },
    { head: "Checkout time", key: "checkOutTime", type: "date" },
    { head: "Checkin Status", key: "checkIn", type: "statuscheck" },
  ];

  const GetManyData = async () => {
    try {
      let res: any;
      const body: any = {
        page: state.currentPage,
        pageSize: 10,
      };
      if (state.search.length > 0) {
        body.search = state.search;
      }
      if (state.filterStatus.length > 0) {
        body.status = state.filterStatus;
      }
      if (state.filterVehicle.length > 0) {
        body.vhNumber = state.filterVehicle;
      }
      if (state.filterDriver.length > 0) {
        body.dId = state.filterDriver;
      }
      if (driverCheckin) {
        console.log("in");

        body.dId = params.id;
      }
      res = await Models.checkin.getManyCheckin(body);
      setState({
        totalItems: res?.count,
        data: res?.data,
      });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };
  const getAllCheckin = async () => {
    let data: any = [];
    let data2: any = [];
    try {
      const res: any = await Models.checkin.getManyCheckin({});
      for (let checkin of res.data) {
        data.push({
          label:
            checkin?.vehicleId?.vhModel && checkin?.vehicleId?.vhNumber
              ? `${checkin?.vehicleId?.vhModel} - ${checkin?.vehicleId?.vhNumber}`
              : checkin?.vehicleId?.vhModel || checkin?.vehicleId?.vhNumber,
          value: checkin?.vehicleId?.vhNumber,
        });
        data2.push({
          label: checkin?.driverId?.dNumber,
          value: checkin?.driverId?._id,
        });
      }
      data = _.orderBy(data, ["label"]);
      data2 = _.orderBy(data2, ["label"]);

      data.unshift({
        label: "ALL VEHICLES",
        value: 0,
      });
      data2.unshift({
        label: "ALL DRIVERS",
        value: 0,
      });
      setState({
        checkinVehicleData: _.uniqBy(data, "label"),
        checkinDriverData: _.uniqBy(data2, "label"),
      });
    } catch (err: any) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getAllCheckin();
  }, []);
  useEffect(() => {
    GetManyData();
  }, [
    state.search,
    state.currentPage,
    state.filterStatus,
    state.filterVehicle,
    state.filterDriver,
  ]);
  const handleView = (data) => {
    navigate(`/checkin/view/${data?._id}`);
  };

  const handlePageChange = (page: number) => {
    setState({ currentPage: page });
  };
  const handleExport = async () => {
    const res: any = await Models.checkin.exportCheckin();
    const compressedString = res.data;
    const compressedBuffer = Buffer.from(compressedString, "base64");
    const base64String = pako.ungzip(compressedBuffer, { to: "base64" });
    createExcelFile(base64String);
  };
  return (
    <div className="org_screen">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="head h3">Checkin</div>
        </div>
        <div style={{justifyContent : "flex-end"}} className="search_wrapper">
          {/* <Search
            value={state.search}
            onChange={(search) => setState({ search })}
          /> */}

          <div className="button_group">
            <Filter
              onClick={() => setState({ toggleFilter: !state.toggleFilter })}
            />
            <Button
              value="Export"
              onClick={() => {
                handleExport();
              }}
            />
          </div>
        </div>
      </div>
      <div className="all_filter_container">
        {state.toggleFilter && (
          <div className="all_filter_dropdown_wrapper">
            <div className="all_filter_dropdown_item">
              <SelectDropdown
                notfound={"Not found"}
                placeholder={"Status"}
                data={status}
                onChange={(filterStatus: any) =>
                  setState({
                    filterStatus: filterStatus.value,
                    currentPage: 1,
                    totalItems: 0,
                  })
                }
                value={state.filterStatus}
              />
            </div>
            <div className="all_filter_dropdown_item">
              <SelectDropdown
                notfound={"Not found"}
                placeholder={"Model - vhNumber"}
                data={state.checkinVehicleData}
                onChange={(filterVehicle: any) =>
                  setState({
                    filterVehicle: filterVehicle.value,
                    currentPage: 1,
                    totalItems: 0,
                  })
                }
                value={state.filterVehicle}
              />
            </div>
            <div className="all_filter_dropdown_item">
              <SelectDropdown
                notfound={"Not found"}
                placeholder={"dNumber"}
                data={state.checkinDriverData}
                onChange={(filterDriver: any) =>
                  setState({
                    filterDriver: filterDriver.value,
                    currentPage: 1,
                    totalItems: 0,
                  })
                }
                value={state.filterDriver}
              />
            </div>
          </div>
        )}
      </div>
      <div className="drivertable">
        <Table
          data={state.data}
          totalDocs={state.totalDocs}
          loading={state.loading}
          theads={thead}
          link=""
          loadMore={() => {}}
          actions={[
            {
              icon: "view",
              onClick: handleView,
            },
          ]}
        />
      </div>
      <div className="driver_pagination">
        <Pagination
          totalItems={state.totalItems}
          itemsPerPage={10}
          currentPage={state.currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

import instance from "utils/axios.utils";

const auth = {
  register: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/register";
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  login: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/login";
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  forgotPassword1: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/forgotPassword";
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  forgotPassword2: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/forgotPassword/verify-otp";
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  forgotPassword3: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/forgotPassword/reset";
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  changePassword: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/changePassword";
      instance()
        .patch(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  clientChangePassword: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = "clients/init-mandate";
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default auth;
